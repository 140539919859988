import { useAccount } from "wagmi";

import { getContract } from "config/contracts";
import { getV2Tokens, NATIVE_TOKEN_ADDRESS } from "config/tokens";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";
import { useMulticall } from "lib/multicall";
import { TokenBalancesData } from "./types";

import useWallet from "lib/wallets/useWallet";
import { ethers } from "ethers";
import { useEffect, useState } from "react";

import Multicall from "abis/Multicall.json";
import Token from "abis/Token.json";

import CopyaccountRouter from "abis/CopyaccountRouter.json";

type BalancesDataResult = {
  balancesData?: TokenBalancesData;
  error?: Error;
};

type BalanceData = {
  balance?: string;
};

export function useTokenBalances(
  chainId: number,
  overrideAccount?: string | undefined,
  overrideTokenList?: {
    address: string;
    isSynthetic?: boolean;
  }[],
  refreshInterval?: number
): BalancesDataResult {
  const { address: currentAccount } = useAccount();

  const account = overrideAccount ?? currentAccount;

  const { data, error } = useMulticall(chainId, "useTokenBalances", {
    key: account ? [account, ...(overrideTokenList || []).map((t) => t.address)] : null,

    refreshInterval,

    request: () =>
      (overrideTokenList ?? getV2Tokens(chainId)).reduce((acc, token) => {
        // Skip synthetic tokens
        if (token.isSynthetic) return acc;

        const address = token.address;

        if (address === NATIVE_TOKEN_ADDRESS) {
          acc[address] = {
            contractAddress: getContract(chainId, "Multicall"),
            abi: Multicall.abi,
            calls: {
              balance: {
                methodName: "getEthBalance",
                params: [account],
              },
            },
          };
        } else {
          acc[address] = {
            contractAddress: address,
            abi: Token.abi,
            calls: {
              balance: {
                methodName: "balanceOf",
                params: [account ?? PLACEHOLDER_ACCOUNT],
              },
            },
          };
        }

        return acc;
      }, {}),
    parseResponse: (res) =>
      Object.keys(res.data).reduce((tokenBalances: TokenBalancesData, tokenAddress) => {
        tokenBalances[tokenAddress] = res.data[tokenAddress].balance.returnValues[0];

        return tokenBalances;
      }, {} as TokenBalancesData),
  });

  return {
    balancesData: data,
    error,
  };
}



export function useDirectBalanceFetchold(
  chainId: number,
  overrideAccount?: string | undefined,
  overrideTokenList?: {
    address: string;
    isSynthetic?: boolean;
  }[],
  refreshInterval?: number
): BalancesDataResult {
  const { address: currentAccount } = useAccount();

  const account = overrideAccount ?? currentAccount;

//console.log("getV2Tokens(chainId)",getV2Tokens(chainId))

  const { data, error } = useMulticall(chainId, "useTokenBalances", {
    key: account ? [account, ...(overrideTokenList || []).map((t) => t.address)] : null,

    refreshInterval,

    request: () =>
      (overrideTokenList ?? getV2Tokens(chainId)).reduce((acc, token) => {
        // Skip synthetic tokens
        if (token.isSynthetic) return acc;

        const address = token.address;

        if (address === NATIVE_TOKEN_ADDRESS) {
      //    console.log("1fetching native token value")
          acc[address] = {
            contractAddress: getContract(chainId, "CopyTradingRouter"),
            abi: CopyaccountRouter.abi,
            calls: {
              balance: {
                methodName: "getUserETHBalanace",
                params: [account],
              },
            },
          };
        } else {
       //   console.log("1fetching non-native token value")
          acc[address] = {
            contractAddress: address,
            abi: Token.abi,
            calls: {
              balance: {
                methodName: "balanceOf",
                params: [account ?? PLACEHOLDER_ACCOUNT],
              },
            },
          };
        }

        return acc;
      }, {}),
    parseResponse: (res) =>
      Object.keys(res.data).reduce((tokenBalances: TokenBalancesData, tokenAddress) => {
        tokenBalances[tokenAddress] = res.data[tokenAddress].balance.returnValues[0];

        return tokenBalances;
      }, {} as TokenBalancesData),
  });

  return {
    balancesData: data,
    error,
  };
}




export function useDirectBalanceFetch(
  chainId: number,
  overrideAccount?: string,
): BalanceData {
  const { account: currentAccount, signer } = useWallet();
  const account = overrideAccount ?? currentAccount;

  const [balance, setBalance] = useState<string>("");
 // console.log("fetching balance data",balance)

  useEffect(() => {
    if (!account || !signer) return;
    let accountAddress = overrideAccount ?? currentAccount ?? signer.address;



    async function fetchBalance() {
      try {


        const contractAddress = getContract(chainId, "CopyTradingRouter");
        const contract = new ethers.Contract(contractAddress, CopyaccountRouter.abi, signer);

    //    const balance = await contract.getUserETHBalance(account);
        const balance = await contract.getUserETHBalanace(currentAccount);
     //   console.log("fetching user balance for",balance)
        setBalance(balance.toString());
      } catch (error) {
        console.error("Failed to fetch balance:", error);
      }
    }

    fetchBalance();
  }, [account, chainId, signer]);

  return { balance };
}



interface User {
  isActive: boolean;
  subscriptionEndDate: string; // Using string to represent big numbers
  Margin: string;
  leverage: string;
  maxMargin: string;
  allowedMarkets: string[];
  stopLoss: string;
  takeProfit: string;
  balance: string;
  reverseCopy: boolean;
  uintnew: string;
  skipLowerLev: string;
  skipLowerColl: string;
  keepLeverageasTrader: boolean;
  executeAllOrders: boolean;
}

interface UserData {
  userDetails: User | null;
}


export function useUserDetails(
  chainId: number,
  overrideAccount?: string,
): User | null {
  const { account: currentAccount, signer } = useWallet();
  const account = overrideAccount ?? currentAccount;

  const [userDetails, setUserDetails] = useState<User | null>(null);

  useEffect(() => {
    if (!account || !signer) return;
    const accountAddress = overrideAccount ?? currentAccount ?? signer.address;

    async function fetchUserDetailsFromContract() {
      try {
        const contractAddress = getContract(chainId, 'CopyTradingRouter');
        const contract = new ethers.Contract(
          contractAddress,
          CopyaccountRouter.abi,
          signer,
        );

        const userDetailsFromContract = await contract.getUserDetails(accountAddress);
      //  console.log('Fetched user details:', userDetailsFromContract);

        // Process the returned userDetailsFromContract to convert BigNumbers to strings
        const processedUserDetails: User = {
          isActive: userDetailsFromContract.isActive,
          subscriptionEndDate: userDetailsFromContract.subscriptionEndDate.toString(),
          Margin: userDetailsFromContract.Margin.toString(),
          leverage: userDetailsFromContract.leverage.toString(),
          maxMargin: userDetailsFromContract.maxMargin.toString(),
          allowedMarkets: userDetailsFromContract.allowedMarkets,
          stopLoss: userDetailsFromContract.stopLoss.toString(),
          takeProfit: userDetailsFromContract.takeProfit.toString(),
          balance: userDetailsFromContract.balance.toString(),
          reverseCopy: userDetailsFromContract.reverseCopy,
          uintnew: userDetailsFromContract.uintnew.toString(),
          skipLowerLev: userDetailsFromContract.skipLowerLev.toString(),
          skipLowerColl: userDetailsFromContract.skipLowerColl.toString(),
          keepLeverageasTrader: userDetailsFromContract.keepLeverageasTrader,
          executeAllOrders: userDetailsFromContract.executeAllOrders,
        };

        setUserDetails(processedUserDetails);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }

    fetchUserDetailsFromContract();
  }, [account, chainId, signer, overrideAccount, currentAccount]);

  return userDetails;
}

export function fetchUserDetails(
  chainId: number,
  overrideAccount?: string,
): UserData {
  const { account: currentAccount, signer } = useWallet();
  const account = overrideAccount ?? currentAccount;

  const [userDetails, setUserDetails] = useState<User | null>(null);
 // console.log('Fetching user details', userDetails);

  useEffect(() => {
    if (!account || !signer) return;
    const accountAddress = overrideAccount ?? currentAccount ?? signer.address;

    async function fetchUserDetailsFromContract() {
      try {
        const contractAddress = getContract(chainId, 'CopyTradingRouter');
        const contract = new ethers.Contract(contractAddress, CopyaccountRouter.abi, signer);

        const userDetailsFromContract = await contract.getUserDetails(accountAddress);
      //  console.log('Fetched user details:', userDetailsFromContract);

        // Process the returned userDetailsFromContract to convert BigNumbers to strings
        const processedUserDetails: User = {
          isActive: userDetailsFromContract.isActive,
          subscriptionEndDate: userDetailsFromContract.subscriptionEndDate.toString(),
          Margin: userDetailsFromContract.Margin.toString(),
          leverage: userDetailsFromContract.leverage.toString(),
          maxMargin: userDetailsFromContract.maxMargin.toString(),
          allowedMarkets: userDetailsFromContract.allowedMarkets,
          stopLoss: userDetailsFromContract.stopLoss.toString(),
          takeProfit: userDetailsFromContract.takeProfit.toString(),
          balance: userDetailsFromContract.balance.toString(),
          reverseCopy: userDetailsFromContract.reverseCopy,
          uintnew: userDetailsFromContract.uintnew.toString(),
          skipLowerLev: userDetailsFromContract.skipLowerLev.toString(),
          skipLowerColl: userDetailsFromContract.skipLowerColl.toString(),
          keepLeverageasTrader: userDetailsFromContract.keepLeverageasTrader,
          executeAllOrders: userDetailsFromContract.executeAllOrders,
        };

        setUserDetails(processedUserDetails);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    }

    fetchUserDetailsFromContract();
  }, [account, chainId, signer]);

  return { userDetails };
}



// Define the interface for the watchlist data
//interface WatchlistAddress {
//  addr: string;
//  name: string; // Adjust fields as needed
//}

//interface WatchlistData {
 // watchlistAddresses: WatchlistAddress[] | null;
 // isLoading: boolean;
//  error: Error | null;
//}


// Define the interface for the watchlist data
interface WatchlistAddress {
  addr: string;
  isActiveMonitor: boolean;
  totalPastTrades: number;
  lastTradeId: string;
}

interface WatchlistData {
  watchlistAddresses: WatchlistAddress[] | null;
  isLoading: boolean;
  error: Error | null;
}

export function useFetchWatchlistAddressesold(
  chainId: number,
  overrideAccount?: string,
): WatchlistData {
  const { account: currentAccount, signer } = useWallet();
  const account = overrideAccount ?? currentAccount;

  const [watchlistAddresses, setWatchlistAddresses] = useState<WatchlistAddress[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!account || !signer) {
      setIsLoading(false);
      return;
    }

    const accountAddress = overrideAccount ?? currentAccount ?? account;

    async function fetchWatchlistFromContract() {
      try {
        setIsLoading(true);

     //   const provider = new ethers.providers.Web3Provider(ethereum);
     //   const contractAddress = getContract(chainId, 'CopyTradingRouter');
      //  const contract = new ethers.Contract(contractAddress, CopyaccountRouter.abi, provider);

        const contractAddress = getContract(chainId, 'CopyTradingRouter');
        const contract = new ethers.Contract(contractAddress, CopyaccountRouter.abi, signer);

        const userDetailsFromContract = await contract.getUserDetails(accountAddress);
      //  console.log('Fetched user details:', userDetailsFromContract);


        const watchlistFromContract = await contract.getWatchlistAddresses(accountAddress);

        // Process the watchlist data
        const processedWatchlist: WatchlistAddress[] = watchlistFromContract.map((item: any) => ({
          addr: item.addr,
          name: item.name, // Adjust fields as per your struct
        }));

        setWatchlistAddresses(processedWatchlist);
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to fetch watchlist addresses:', err);
        setError(err as Error);
        setIsLoading(false);
      }
    }

    fetchWatchlistFromContract();
  }, [account, chainId]);

  return { watchlistAddresses, isLoading, error };
}



export function useFetchWatchlistAddresses(
  chainId: number,
  overrideAccount?: string,
): WatchlistData {
  const { account: currentAccount, signer } = useWallet();
  const account = overrideAccount ?? currentAccount;

  const [watchlistAddresses, setWatchlistAddresses] = useState<WatchlistAddress[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!account || !signer) {
      setIsLoading(false);
      return;
    }

    const accountAddress = overrideAccount ?? currentAccount ?? account;

    async function fetchWatchlistFromContract() {
      try {
        setIsLoading(true);

        const contractAddress = getContract(chainId, 'CopyTradingRouter');
        const contract = new ethers.Contract(contractAddress, CopyaccountRouter.abi, signer);

        const watchlistFromContract = await contract.getWatchlistAddresses(accountAddress);

        // Process the watchlist data
        const processedWatchlist: WatchlistAddress[] = watchlistFromContract.map((item: any) => ({
          addr: item.addr,
          isActiveMonitor: item.isActiveMonitor,
          totalPastTrades: Number(item.totalPastTrades.toString()),
          lastTradeId: item.lastTradeId,
        }));

        setWatchlistAddresses(processedWatchlist);
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to fetch watchlist addresses:', err);
        setError(err as Error);
        setIsLoading(false);
      }
    }

    fetchWatchlistFromContract();
  }, [account, chainId, signer]);

  return { watchlistAddresses, isLoading, error };
}