import { Trans, t } from "@lingui/macro";
import cx from "classnames";
import { ApproveTokenButton } from "components/ApproveTokenButton/ApproveTokenButton";
import Button from "components/Button/Button";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Modal from "components/Modal/Modal";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipWithPortal from "components/Tooltip/TooltipWithPortal";
import { StatusNotification } from "components/Synthetics/StatusNotification/StatusNotification";
import { getContract } from "config/contracts";
import { getNativeToken, getWrappedToken } from "config/tokens";
import {
  useIsCopyaccountActive,
  useCopyaccount,
  useCopyaccountActionCounts,
  useCopyaccountAddress,
  useCopyaccountDefaultExecutionFee,
  useCopyaccountGenerateCopyaccount,
  useCopyaccountModalOpen,
 // useSelectedAccountModalOpen,
  useCopyaccountNotificationState,
  useCopyaccountPendingTx,
  useCopyaccountState,
  useCopyaccountInsufficientFunds,
} from "context/CopyaccountContext/CopyaccountContext";
import { useBigNumberInput } from "domain/synthetics/common/useBigNumberInput";
import { useTransactionPending } from "domain/synthetics/common/useTransactionReceipt";
import { useGasPrice } from "domain/synthetics/fees";
import { getCurrentMaxActionsCount } from "domain/synthetics/copyaccount/getCurrentActionsCount";
import { initCopyaccount, addUserOrUpdate } from "domain/synthetics/copyaccount/initCopyaccount";
import { removeCopyaccount } from "domain/synthetics/copyaccount/removeCopyaccount";
import { withdrawFromCopyaccount , removeWatchlistAddress } from "domain/synthetics/copyaccount/withdrawFromCopyaccount";
import {
  getNeedTokenApprove,
  getNeedTokenApprove2,
  useTokenBalances,
  useDirectBalanceFetch,
  useTokensAllowanceData,
  useTokensDataRequest,
  getTokenData,
  fetchUserDetails,
  useUserDetails,
  useFetchWatchlistAddresses
} from "domain/synthetics/tokens";
import copyIcon from "img/ic_copy_20.svg";
import externalLinkIcon from "img/ic_new_link_20.svg";
import { useChainId } from "lib/chains";
import { helperToast } from "lib/helperToast";
import { getAccountUrl } from "lib/legacy";
import { formatTokenAmount } from "lib/numbers";
import { getByKey } from "lib/objects";
import { shortenAddressOrEns } from "lib/wallets";
import useWallet from "lib/wallets/useWallet";
import { formatUsd } from "lib/numbers";
import { convertToUsd } from "domain/synthetics/tokens";
import { ChangeEvent, ReactNode, memo, useCallback, useEffect, useMemo, useState, forwardRef, useRef } from "react";
import { useCopyToClipboard, usePrevious } from "react-use";
import { CopyaccountNotification } from "../StatusNotification/CopyaccountNotification";
import "./CopyaccountModal.scss";
import { CopyaccountStatus } from "./CopyaccountStatus";
import { getApproxCopyaccountActionsCountByBalance, getButtonState, getDefaultValues } from "./utils";
import { usePendingTxns } from "lib/usePendingTxns";
import React from 'react';
import { ethers } from 'ethers';





import { Link, useHistory, useParams } from "react-router-dom";
import { useMedia } from "react-use";
import { checksumAddress, isAddress, type Address } from "viem";

import { CHAIN_NAMES_MAP, SUPPORTED_CHAIN_IDS, getChainName } from "config/chains";
import { getIsV1Supported } from "config/features";
import { getIcon } from "config/icons";
import { SyntheticsStateContextProvider } from "context/SyntheticsStateContext/SyntheticsStateContextProvider";
import useSearchParams from "lib/useSearchParams";

import AddressView from "components/AddressView/AddressView";
import Footer from "components/Footer/Footer";
import PageTitle from "components/PageTitle/PageTitle";
import { NETWORK_ID_SLUGS_MAP, NETWORK_QUERY_PARAM, NETWORK_SLUGS_ID_MAP, VERSION_QUERY_PARAM } from "pages/AccountDashboard/constants";
import { buildAccountDashboardUrl } from "pages/AccountDashboard/AccountDashboard";


export type FormState = "empty" | "inactive" | "activated";


function usePageParams(initialChainId: number) {
  const history = useHistory();
  const pathname = history.location.pathname;
 // console.log({pathname: pathname});

  // Extract the account address from the pathname
  const accountFromPath = pathname.split('/accounts/')[1];
 // console.log({accountFromPath: accountFromPath});

  const queryParams = useSearchParams<{ network?: string; v?: string }>();
//  console.log({queryParams: queryParams});
  const chainIdFromParams = NETWORK_SLUGS_ID_MAP[queryParams.network || ""] as number | undefined;
 // console.log({chainIdFromParams: chainIdFromParams});
  const chainId = chainIdFromParams ?? initialChainId;
 // console.log({chainId: chainId});

  // Use useMemo to memoize the checksummed address

  // Use useMemo to memoize the checksummed address
  const copyaccount = useMemo(() => {
    if (accountFromPath && isAddress(accountFromPath.toLowerCase())) {
      return checksumAddress(accountFromPath as `0x${string}`);
    } else {
      console.error("Invalid or missing account address in URL.");
    }
    return accountFromPath;
  }, [accountFromPath]);

  //console.log({copyaccount: copyaccount});


  const version = parseInt(queryParams.v ?? "2");

  return { chainId, version, copyaccount };
}



//const MainView = memo(() => {


  interface MainViewProps {
    copyaccount?: string;  // Use optional if the prop might not always be provided
  }


export function CopyaccountModal() {
  const { chainId: initialChainId } = useChainId();
  const history = useHistory();

  const pathname = history.location.pathname;
 // console.log({pathname: pathname});
  
  const { version, copyaccount } = usePageParams(initialChainId);

 // console.log({accountnew: copyaccount})
//  console.log({version: version})

  const [isVisible, setIsVisible] = useCopyaccountModalOpen();
//  const setSelectedAccount = useSelectedAccountModalOpen();


  return (
    <Modal label={t`Copy Trading`} isVisible={isVisible} setIsVisible={setIsVisible}  >
      <div className="CopyaccountModal-content">
      <MainView copyaccount={copyaccount} />
      </div>
    </Modal>
  );
}

interface User {
  isActive: boolean;
  subscriptionEndDate: string;
  Margin: string;
  leverage: string;
  maxMargin: string;
  allowedMarkets: string[];
  stopLoss: string;
  takeProfit: string;
  balance: string;
  reverseCopy: boolean;
  uintnew: string;
  skipLowerLev: string;
  skipLowerColl: string;
  keepLeverageasTrader: boolean;
  executeAllOrders: boolean;
}

const MainView = memo<MainViewProps>(({ copyaccount }) => {
  
  const oneClickTradingState = useCopyaccountState();
  const { chainId } = useChainId();
  const { signer, account } = useWallet();

  const [withdrawalLoading, setWithdrawalLoading] = useState(false);

  interface WatchlistAddress {
    addr: string;
    name?: string; // If you have additional fields
  }
  
  const [watchlistAddressesloading, setWatchlistAddresses] = useState<WatchlistAddress[]>([]);
  
 // const [watchlistAddressesisloading, setWatchlistAddresses] = useState(false);
  const [isCopyaccountUpdating, setIsCopyaccountUpdating] = useState(false);
  const { tokensData } = useTokensDataRequest(chainId);
  const subaccountAddress = useCopyaccountAddress();
  const mainBalances = useTokenBalances(chainId, account);
 // const subBalances = useTokenBalances(chainId, subaccountAddress ?? undefined);
 //// const subBalances = signer && account ? useDirectBalanceFetch(chainId, account) : null;

 
   const subBalances = useDirectBalanceFetch(chainId, account);
 //  const userDetails =  fetchUserDetails(chainId, account)

   //const { userDetails } = fetchUserDetails(chainId, account);



  // const { watchlistAddresses, isLoading, error } = useFetchWatchlistAddresses(chainId, account);
  const { watchlistAddresses, isLoading, error } = useFetchWatchlistAddresses(chainId, account);

   const [isRemoving, setIsRemoving] = useState<boolean>(false);

  // console.log("watchlistAddresses",watchlistAddresses)

 // const subBalances2 = useDirectBalanceFetch(chainId, signer, account);

 // const subBalances2 = useDirectBalanceFetch(chainId, account);
  const wrappedToken = getWrappedToken(chainId);
  const wrappedTokenData = getTokenData(tokensData, wrappedToken.address);
  const nativeToken = getNativeToken(chainId);
  const nativeTokenData = getTokenData(tokensData, nativeToken.address);
  const mainAccNativeTokenBalance = getByKey(mainBalances.balancesData, nativeToken.address);
  const mainAccWrappedTokenBalance = getByKey(mainBalances.balancesData, wrappedToken.address);
//  const subAccNativeTokenBalance = getByKey(subBalances.balancesData, nativeToken.address);
 const subAccNativeTokenBalance = (subBalances.balance);


 //isInsufficientFunds = useCopyaccountInsufficientFunds(defaultExecutionFee);

 //const isCopyaccountActive = useIsCopyaccountActive();
 //const defaultExecutionFee = useCopyaccountDefaultExecutionFee();
 //const networkFee = useCopyaccountDefaultNetworkFee();
 //const required = (requiredBalance ?? defaultExecutionFee ?? 0n) + networkFee;


 //if (!isCopyaccountActive) 
// {return false;}

  //console.log('subBalances',subBalances)
//  console.log('subBalances2',subBalances)
  //console.log('subAccNativeTokenBalance',subAccNativeTokenBalance)
//  console.log('mainAccNativeTokenBalance',mainAccNativeTokenBalance)
  const subaccountExplorerUrl = useMemo(() => getAccountUrl(chainId, copyaccount), [chainId, copyaccount]);
  const [, setPendingTxns] = usePendingTxns();

  const maxAllowedActionsInputRef = useRef<HTMLInputElement>(null);
  const topUpInputRef = useRef<HTMLInputElement>(null);
  const convertInputRef = useRef<HTMLInputElement>(null);
  const convertLeverageRef = useRef<HTMLInputElement>(null);

  const [selectedPairs, setSelectedPairs] = useState<string[]>([]);
  const [leverage, setLeverage] = useState(5); // default leverage value

  const [maxMargin, setMaxMargin] = useState('0'); // State to hold the value of maxMargin
  const [Margin, setMargin] = useState('500'); // State to hold the value of maxMargin
  const [reverseCopy, setReverseCopy] = useState(false);
  const [copyAllTrades, setcopyAllTrades] = useState(false);
  const [leverageCopy, setLeverageCopy] = useState(false);
   const convertMaxMarginRef = useRef<HTMLInputElement>(null); // Ref for the input field
   const convertMarginRef = useRef<HTMLInputElement>(null); // Ref for the input field

   const [stopLossValue, setStopLossValue] = useState('100');
   const [stopLossType, setStopLossType] = useState('USD'); // 'USD' or 'ROI'
   
   const [takeProfitValue, setTakeProfitValue] = useState('0');
   const [takeProfitType, setTakeProfitType] = useState('USD'); // 'USD' or 'ROI'


   const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] = useState(false);


   const [skipLowerLev, setskipLowerLev] = useState('0'); // State to hold the value of maxMargin
   const convertskipLowerLevRef = useRef<HTMLInputElement>(null); // Ref for the input field

   const [skipLowerColl, setskipLowerColl] = useState('0'); // State to hold the value of maxMargin
   const convertskipLowerCollRef = useRef<HTMLInputElement>(null); // Ref for the input field
   //const userDetails: User | null = fetchUserDetails(chainId, account);
   const userDetails: User | null = useUserDetails(chainId, account);

   const [initialValuesSet, setInitialValuesSet] = useState(false);
 
//console.log("userDetails",userDetails)

  useEffect(() => {
    if (userDetails && !initialValuesSet) {
      setSelectedPairs(userDetails.allowedMarkets || []);
      // Adjust leverage (scaled by 10)
  // Adjust leverage (scaled by 10)
  const leverageRawValue = userDetails.leverage || '500';
  const leverageBigInt = BigInt(leverageRawValue);
  const leverageValue = Number(leverageBigInt) / 10; // Divide by scaling factor 10
  setLeverage(leverageValue);
 //   setLeverage(userDetails.leverage ? parseInt(userDetails.leverage, 10) : 5);
 const marginRawValue = userDetails.Margin || Margin;
 const marginBigInt = BigInt(marginRawValue);
 const marginValue = Number(marginBigInt) / 1000_000; // Divide by scaling factor 100,000
 setMargin(marginValue.toString());

 const maxMarginRawValue = userDetails.maxMargin || '500';
 const maxMarginBigInt = BigInt(maxMarginRawValue);
 const maxMarginValue = Number(maxMarginBigInt) / 1000_000; // Divide by scaling factor 100,000
 setMaxMargin(maxMarginValue.toString());
 //   setMaxMargin(userDetails.maxMargin ?? '0');
 //   setMargin(userDetails.Margin ?? '0');
    setReverseCopy(userDetails.reverseCopy ?? false);
    setcopyAllTrades(userDetails.executeAllOrders ?? false);
    setLeverageCopy(userDetails.keepLeverageasTrader ?? false);
    const stopLossValue = userDetails.stopLoss || '100';
    const stopLossValueInt = BigInt(stopLossValue);
    const stopLossValueNum = Number(stopLossValueInt) / 1000_000; // Divide by scaling factor 100,000
    setStopLossValue(stopLossValueNum.toString());
 //   setStopLossValue(userDetails.stopLoss ?? '0');

 const takeProfitValue = userDetails.takeProfit || '100';
 const takeProfitValueInt = BigInt(takeProfitValue);
 const takeProfitValueNum = Number(takeProfitValueInt) / 1000_000; // Divide by scaling factor 100,000
 setTakeProfitValue(takeProfitValueNum.toString());
  //  setTakeProfitValue(userDetails.takeProfit ?? '0');
    setskipLowerLev(userDetails.skipLowerLev ?? '0');
    setskipLowerColl(userDetails.skipLowerColl ?? '0');

      setInitialValuesSet(true);
    }
  //}, [userDetails, initialValuesSet]);
}, [initialValuesSet]);

  
   useEffect(() => {
    if (userDetails) {
      setSelectedPairs(userDetails.allowedMarkets || []);
        // Adjust leverage (scaled by 10)
    // Adjust leverage (scaled by 10)
    const leverageRawValue = userDetails.leverage || '0';
    const leverageBigInt = BigInt(leverageRawValue);
    const leverageValue = Number(leverageBigInt) / 10; // Divide by scaling factor 10
    setLeverage(leverageValue);
   //   setLeverage(userDetails.leverage ? parseInt(userDetails.leverage, 10) : 5);
   const marginRawValue = userDetails.Margin || Margin;
   const marginBigInt = BigInt(marginRawValue);
   const marginValue = Number(marginBigInt) / 1000_000; // Divide by scaling factor 100,000
   setMargin(marginValue.toString());

   const maxMarginRawValue = userDetails.maxMargin || '0';
   const maxMarginBigInt = BigInt(maxMarginRawValue);
   const maxMarginValue = Number(maxMarginBigInt) / 1000_000; // Divide by scaling factor 100,000
   setMaxMargin(maxMarginValue.toString());
   //   setMaxMargin(userDetails.maxMargin ?? '0');
   //   setMargin(userDetails.Margin ?? '0');
      setReverseCopy(userDetails.reverseCopy ?? false);
      setcopyAllTrades(userDetails.executeAllOrders ?? false);
      setLeverageCopy(userDetails.keepLeverageasTrader ?? false);
      const stopLossValue = userDetails.stopLoss || '0';
      const stopLossValueInt = BigInt(stopLossValue);
      const stopLossValueNum = Number(stopLossValueInt) / 1000_000; // Divide by scaling factor 100,000
      setStopLossValue(stopLossValueNum.toString());
   //   setStopLossValue(userDetails.stopLoss ?? '0');

   const takeProfitValue = userDetails.takeProfit || '0';
   const takeProfitValueInt = BigInt(takeProfitValue);
   const takeProfitValueNum = Number(takeProfitValueInt) / 1000_000; // Divide by scaling factor 100,000
   setTakeProfitValue(takeProfitValueNum.toString());
    //  setTakeProfitValue(userDetails.takeProfit ?? '0');
      setskipLowerLev(userDetails.skipLowerLev ?? '0');
      setskipLowerColl(userDetails.skipLowerColl ?? '0');
    }
 // }, [userDetails]);
}, []);

   const handleStopLossTypeChange = (event) => {
    setStopLossType(event.target.value);
  };
  
  const handleStopLossValueChange = (event) => {
    setStopLossValue(event.target.value);
  };

  const handleReverseCopyChange = (newValue) => {
    setReverseCopy(newValue);
  };

  const handlecopyAllTradesChange = (newValue) => {
    setcopyAllTrades(newValue);
  };

  const handleLeverageCopyChange = (newValue) => {
    setLeverageCopy(newValue);
  };

  const handleTakeProfitTypeChange = (event) => {
    setTakeProfitType(event.target.value);
  };
  
  const handleTakeProfitChange = (event) => {
    setTakeProfitValue(event.target.value);
  };



const handleRemoveWatchlistAddress = useCallback(
  async (addressToRemove: string) => {
    if (!account) throw new Error("account is not defined");
    if (!signer) throw new Error("signer is not defined");

    setIsRemoving(true);

    try {
      helperToast.success(
        <StatusNotification title={`Removing from Watchlist`}>
          {`Removing ${addressToRemove} from your watchlist`}
        </StatusNotification>
      );

      await removeWatchlistAddress(chainId, signer, addressToRemove, setPendingTxns);

      helperToast.success(
        <StatusNotification title={`Removed from Watchlist`}>
          {`Removed ${addressToRemove} from your watchlist`}
        </StatusNotification>
      );

      // Update the local state to reflect the removal
    //  setWatchlistAddresses((prev) => prev?.filter((item) => item.addr !== addressToRemove) || []);
    } catch (error) {
      console.error('Failed to remove address:', error);
      helperToast.error(`Failed to remove address: ${error.message}`);
    } finally {
      setIsRemoving(false);
    }
  },
  [account, signer, chainId, setPendingTxns]
);
  
const calculateStopLossInUSD = () => {
  // Ensure values are treated as numbers
  const numericMaxMargin = parseFloat(Margin);
   // Since `leverage` is already a number, no need to parse it
   const numericLeverage = leverage;
  const numericStopLossValue = parseFloat(stopLossValue);

  // Check if all inputs are valid numbers
  if (!isNaN(numericMaxMargin) && !isNaN(numericLeverage) && !isNaN(numericStopLossValue)) {
    if (stopLossType === 'ROI') {
      const result = numericMaxMargin * numericLeverage * (numericStopLossValue / 100);
      console.log("Stoploss %",result.toFixed(2))
      return result.toFixed(2);  // Format to two decimal places
    }
    console.log("Stoploss ROI",stopLossValue)
    return stopLossValue;  // Assuming stopLossValue is a string that represents USD value directly
  } else {
    console.error("Invalid inputs for calculating stop loss in USD");
    return "Error";  // Return an error or handle this situation appropriately
  }
};



const calculateTakeProfitInUSD = () => {
  // Ensure values are treated as numbers
  const numericMaxMargin = parseFloat(Margin);
   // Since `leverage` is already a number, no need to parse it
   const numericLeverage = leverage;
  const numericTakeProfitValue = parseFloat(takeProfitValue);

  // Check if all inputs are valid numbers
  if (!isNaN(numericMaxMargin) && !isNaN(numericLeverage) && !isNaN(numericTakeProfitValue)) {
    if (takeProfitType === 'ROI') {
      const result = numericMaxMargin * numericLeverage * (numericTakeProfitValue / 100);
      return result.toFixed(2);  // Format to two decimal places
    }
    return takeProfitValue;  // Assuming stopLossValue is a string that represents USD value directly
  } else {
    console.error("Invalid inputs for calculating Take Profit in USD");
    return "Error";  // Return an error or handle this situation appropriately
  }
};
  

  interface TradingPairsInputProps {
    selectedPairs: string[];
    onChange: (selectedPairs: string[]) => void;
  }
  
  const TradingPairsInput: React.FC<TradingPairsInputProps> = ({ selectedPairs, onChange }) => {

    useEffect(() => {
      // Set default selected pairs if none are selected
      if (selectedPairs.length === 0) {
        onChange(['BTC', 'ETH',"SOL", "ADA", "AAVE", "LINK", "UNI", "XMR", "TRB"]);
      }
    }, [onChange]);

  
    const togglePair = (pair: string) => {
      const newPairs = selectedPairs.includes(pair)
        ? selectedPairs.filter(p => p !== pair)
        : [...selectedPairs, pair];
      onChange(newPairs);
    };
  
    return (
      <div className="trading-pairs-grid">
        {["BTC", "ETH", "SOL", "ADA", "AAVE", "LINK", "UNI", "XMR", "TRB", "TIA"].map(pair => (
          <button
            key={pair}
            onClick={() => togglePair(pair)}
            className={`pair-button ${selectedPairs.includes(pair) ? 'selected' : ''}`}
          >
            {pair}
          </button>
        ))}
      </div>
    );
  };
  


  const handlePairsChange = (pairs: string[]) => {
    setSelectedPairs(pairs);
  };
  interface LeverageSliderProps {
    initialValue: number;
    min: number;
    max: number;
    onChange: (value: number) => void;
  }


  const handleLeverageChange = (newLeverage) => {
   // console.log("New Leverage:", newLeverage); // Perform actions based on new leverage value
    setLeverage(newLeverage);
  };
  const LeverageSlider = ({ initialValue, min, max, onChange }) => {
  

  
    const handleChange = (event) => {
      const newLeverage = Number(event.target.value);
    //  console.log("New Leverage:", newLeverage); 
      setLeverage(newLeverage);
      onChange(newLeverage); // Propagate changes to parent component
    };
  
    return (
      <div className="leverage-slider" style={{ padding: '20px 0' }}>
        <div style={{ marginBottom: '10px' }}>
          <label style={{ display: 'block', textAlign: 'center', marginBottom: '10px' }}>
            Select Your Leverage
          </label>
          <label style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
            Current Leverage: {leverage}x
          </label>
        </div>
        <input
          type="range"
          min={min}
          max={max}
          value={leverage}
          onChange={handleChange}
          style={{ width: '100%', margin: '20px 0' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {[0.1, 10, 20, 30, 50, 60, 75, 85, 100].map(value => (
            <small key={value}>{value}x</small>
          ))}
        </div>
      </div>
    );
  };
  
  

  const defaults = useMemo(() => {
    if (!tokensData) return null;
    const data = tokensData[nativeToken.address];
    if (!data) return null;

    return getDefaultValues(data);
  }, [nativeToken.address, tokensData]);

  const baseFeePerAction = useCopyaccountDefaultExecutionFee();
if(subAccNativeTokenBalance==null){
  return;
}
  const approxNumberOfOperationsByBalance = useMemo(() => {
    const currentAutoTopUpAmount = oneClickTradingState.contractData?.currentAutoTopUpAmount;
    return subAccNativeTokenBalance !== undefined &&
      currentAutoTopUpAmount !== undefined &&
      mainAccWrappedTokenBalance !== undefined &&
      mainAccNativeTokenBalance !== undefined
      ? getApproxCopyaccountActionsCountByBalance(
          mainAccWrappedTokenBalance,
          BigInt(subAccNativeTokenBalance),
          baseFeePerAction,
          currentAutoTopUpAmount
        )
      : null;
  }, [
    baseFeePerAction,
    mainAccNativeTokenBalance,
    mainAccWrappedTokenBalance,
    oneClickTradingState.contractData?.currentAutoTopUpAmount,
    subAccNativeTokenBalance,
  ]);

  const renderCopyaccountBalanceTooltipContent = useCallback(() => {
    let value: ReactNode = "";

    value = approxNumberOfOperationsByBalance?.toString() ?? t`Unknown`;
    return (
      <div>
{}
        <Trans>
         The ETH balance in your Copy Account is used to cover network fees. Use the "Top-up-Gas" option to transfer ETH to your Copy Account if needed.
        </Trans>
        <br />
        <br />
        <StatsTooltipRow label={t`Expected Available Actions`} showDollar={false} value={value} />
        <br />
        <Trans>Expected Actions are based on the current Network Fee.</Trans>
      </div>
    );
  }, [approxNumberOfOperationsByBalance, nativeToken.symbol]);

  const renderMainAccountBalanceTooltipContent = useCallback(() => {
    return (
      <div>
        <Trans>
        You can use your main account balance to top up ETH in your Copy Trading balance for executing trades.
        </Trans>
        <br />
        <br />
        <StatsTooltipRow
          label={wrappedToken.symbol}
          value={formatTokenAmount(mainAccWrappedTokenBalance, wrappedToken.decimals, wrappedToken.symbol, {
            displayDecimals: 4,
          })}
          showDollar={false}
        />
        <StatsTooltipRow
          label={nativeToken.symbol}
          value={formatTokenAmount(mainAccNativeTokenBalance, nativeToken.decimals, nativeToken.symbol, {
            displayDecimals: 4,
          })}
          showDollar={false}
        />
      </div>
    );
  }, [
    mainAccNativeTokenBalance,
    mainAccWrappedTokenBalance,
    nativeToken.decimals,
    nativeToken.symbol,
    wrappedToken.decimals,
    wrappedToken.symbol,
  ]);

  const [, copyToClipboard] = useCopyToClipboard();
  const [formState, setFormState] = useState<FormState>("empty");
  const [nextFormState, setNextFormState] = useState<FormState>("empty");
  const {
    displayValue: topUpString,
    setDisplayValue: setTopUpString,
    setValue: setTopUp,
    value: topUp,
  } = useBigNumberInput(null, nativeToken.decimals, 4);

  const {
    displayValue: maxAutoTopUpAmountString,
    setDisplayValue: setMaxAutoTopUpAmountString,
    setValue: setMaxAutoTopUpAmount,
    value: maxAutoTopUpAmount,
  } = useBigNumberInput(null, wrappedToken.decimals, 4);
  const {
    displayValue: wntForAutoTopUpsString,
    setDisplayValue: setWntForAutoTopUpsString,
    setValue: setWntForAutoTopUps,
    value: wntForAutoTopUps,
  } = useBigNumberInput(null, nativeToken.decimals, 4);
  const {
    displayValue: leverageForTradeString,
    setDisplayValue: setLeverageForTradeString,
    setValue: setLeverageForTrade,
    value: leverageForTrade,
  } = useBigNumberInput(null, nativeToken.decimals, 4);

  const {
    displayValue: maxmarginForTradeString,
    setDisplayValue: setMaxMarginForTradeString,
    setValue: setMaxMarginForTrade,
    value: maxmarginForTrade,
  } = useBigNumberInput(null, 6, 4);



  const {
    displayValue: maxAllowedActionsString,
    setDisplayValue: setMaxAllowedActionsString,
    setValue: setMaxAllowedActions,
    value: maxAllowedActions,
  } = useBigNumberInput(null, 0, 0);

  const isCopyaccountActive = useIsCopyaccountActive();
  //console.log('isCopyaccountActive', isCopyaccountActive);
  const prevIsCopyaccountActive = usePrevious(isCopyaccountActive);

  const [isVisible, setIsVisible] = useCopyaccountModalOpen();

  const [activeTx, setActiveTx] = useCopyaccountPendingTx();
  const isTxPending = useTransactionPending(activeTx);
  const prevIsTxPending = usePrevious(isTxPending);

  const { current: actionsCount, remaining: remainingActionsCount } = useCopyaccountActionCounts();

  useEffect(() => {
    if (nextFormState === formState) return;
    if (isCopyaccountUpdating) return;

    if (!isCopyaccountActive && defaults && nextFormState === "inactive") {
      setTopUp(defaults.topUp);
      setMaxAutoTopUpAmount(defaults.maxAutoTopUpAmount);
      setWntForAutoTopUps(defaults.wntForAutoTopUps);
      setMaxAllowedActions(defaults.maxAllowedActions);
      setFormState("inactive");
    } else if (isCopyaccountActive && nextFormState === "activated") {
      setTopUp(null);
      setWntForAutoTopUps(null);
      setMaxAutoTopUpAmount(oneClickTradingState.contractData?.currentAutoTopUpAmount ?? null);
      setMaxAllowedActions(remainingActionsCount);
      setFormState("activated");
    } else if (nextFormState === "empty") {
      setTopUp(null);
      setMaxAutoTopUpAmount(null);
      setWntForAutoTopUps(null);
      setMaxAllowedActions(null);
      setFormState("empty");
    }
  }, [
    isCopyaccountUpdating,
    defaults,
    formState,
    isCopyaccountActive,
    nextFormState,
    oneClickTradingState.contractData?.currentAutoTopUpAmount,
    remainingActionsCount,
    setMaxAllowedActions,
    setMaxAutoTopUpAmount,
    setTopUp,
    setWntForAutoTopUps,
  ]);

  useEffect(() => {
    if (!isTxPending) {
      setIsCopyaccountUpdating(false);
    }
  }, [isTxPending]);

  const [notificationState, setNotificationState] = useCopyaccountNotificationState();

  const isCopyaccountGenerated = Boolean(subaccountAddress && actionsCount !== null);
//console.log('isCopyaccountGenerated',isCopyaccountGenerated)
//console.log('subaccountAddress',subaccountAddress)
//console.log('actionsCount',actionsCount)
  const showToast = useCallback(() => {
    const toastId = Date.now();

    helperToast.success(
      <CopyaccountNotification
        toastId={toastId}
        subaccountWasAlreadyGenerated={isCopyaccountGenerated}
        subaccountWasAlreadyActivated={isCopyaccountActive}
      />,
      {
        className: "CopyaccountNotification",
        autoClose: false,
        toastId,
      }
    );
  }, [isCopyaccountActive, isCopyaccountGenerated]);

  const handleDeactivateClick = useCallback(async () => {
    if (!subaccountAddress) throw new Error("Copy account address is not set");
    if (!signer) throw new Error("Signer is not set");

    showToast();

    setNotificationState("deactivating");
    setIsCopyaccountUpdating(true);
    if (isCopyaccountActive) {
      try {
        const res = await removeCopyaccount(chainId, signer, subaccountAddress);

        setActiveTx(res.hash);
      } catch (err) {
        setNotificationState("deactivationFailed");
        setIsCopyaccountUpdating(false);
        throw err;
      }
    } else {
      setNotificationState("deactivated");
    }

    oneClickTradingState.clearCopyaccount();
    setIsCopyaccountUpdating(false);
    setNextFormState("inactive");
  }, [
    chainId,
    isCopyaccountActive,
    oneClickTradingState,
    setActiveTx,
    setNotificationState,
    showToast,
    signer,
    subaccountAddress,
  ]);

  useEffect(() => {
    if (isVisible) {
      setNextFormState(isCopyaccountActive ? "activated" : "inactive");
    }
  }, [isVisible, isCopyaccountActive, setNextFormState]);

  useEffect(() => {
    if (prevIsTxPending === true && isTxPending === false) {
      setActiveTx(null);
      setTopUp(null);
      setWntForAutoTopUps(null);
      setNextFormState("activated");

      if (!prevIsCopyaccountActive) {
        setIsVisible(false);
      }
    }
  }, [
    isTxPending,
    prevIsTxPending,
    prevIsCopyaccountActive,
    setIsVisible,
    setActiveTx,
    setNextFormState,
    setTopUp,
    setWntForAutoTopUps,
  ]);

  useEffect(() => {
    if (isTxPending === false && prevIsTxPending === true && notificationState === "activating") {
      setNotificationState("activated");
    }

    if (isTxPending === false && prevIsTxPending === true && notificationState === "deactivating") {
      setNotificationState("deactivated");
    }
  }, [isTxPending, notificationState, prevIsTxPending, setNotificationState]);

  const generateCopyaccount = useCopyaccountGenerateCopyaccount();

  const handleButtonClick = useCallback(async () => {
    async function activateOrUpdate() {
      if (!account) throw new Error("Account is not defined");
      if (!signer) throw new Error("Signer is not defined");

      let address = subaccountAddress;
      let count = actionsCount;

      setNotificationState(isCopyaccountGenerated ? "activating" : "generating");
      showToast();

      if (!isCopyaccountGenerated) {
        try {
          address = await generateCopyaccount();

          // user rejects
          if (!address) {
            setNotificationState("generationFailed");
            return;
          }
        } catch (error) {
          setNotificationState("generationFailed");
          throw error;
        }

        count =
          (await getCurrentMaxActionsCount({
            accountAddress: account!,
      //      signer,
            subaccountAddress: address,
            chainId,
          })) ?? 0n;
      }
     // console.log('count',count)

      if (!address) {
        setNotificationState("activationFailed");
        throw new Error("address is not defined");
      }

      if (count === undefined || count === null) {
        setNotificationState("activationFailed");
        throw new Error("Action counts are not defined");
      }

      setNotificationState("activating");


  if ( isCopyaccountActive === false )
  {

      try {

  //   console.log('running if');
        const tx = await initCopyaccount(
          chainId,
          signer,
          address,
          account,
          isCopyaccountActive ?? false,
          count,
          setPendingTxns,
          {
            topUp: topUp,
            maxAutoTopUpAmount,
            wntForAutoTopUps,
            maxAllowedActions,
          }
        );
        setActiveTx(tx.hash);
      } catch (err) {
        setNotificationState("activationFailed");
        throw err;
      }


  }
  
  else {
  //  console.log('running else');
    try {
      //        console.log('maxAllowedActions',maxAllowedActions);
            await handleUserUpdate();
           //   setActiveTx(tx.hash);
            } catch (err) {
              setNotificationState("activationFailed");
              throw err;
            }
          
  }

    }


    setIsCopyaccountUpdating(true);
    try {
      await activateOrUpdate();
    } catch (error) {
      // if success - setIsCopyaccountUpdating will be set to false in useEffect
      setIsCopyaccountUpdating(false);
      throw error;
    }
  }, [
    account,
    signer,
    subaccountAddress,
    actionsCount,
    setNotificationState,
    isCopyaccountGenerated,
    showToast,
    chainId,
    generateCopyaccount,
    isCopyaccountActive,
    setPendingTxns,
    topUp,
    maxAutoTopUpAmount,
    wntForAutoTopUps,
    maxAllowedActions,
    setActiveTx,
  ]);

  const handleUserUpdate = useCallback(async () => {
    if (!signer || !account) {
      console.error("Signer or account is not defined");
      return;
    }
  
    // Define default values or retrieve them from the UI state / props
    //const watchlistAddress = "0x92812499fF2c040f93121Aab684680a6e603C4A7";  // example watchlist address
    const subscriptionEndDate = Date.now() + 1000 * 60 * 60 * 24 * 1000; // 1 year from now
   // const leverage = 10; // example leverage
   // const maxMargin = 5000; // example maximum margin
   // const allowedMarkets = ["BTC", "ETH", "SOL"]; // example allowed markets
   // const stopLoss = 100; // example stop loss value
  //  const takeProfit = 200; // example take profit value
    const isUserActive = true; // status of the user
    const isWatchlistActiveMonitor = true; // status of the watchlist monitor
  //  const reverseCopy = false;
    const collateralInc = 0; 
    //const skipLowerLev = 1;
   /// const skipLowerColl = 1;  
   // const keepLeverageasTrader = true;  
   // const executeAllOrders = true;  
   // console.log('Maxmargin',maxMargin)
  
    const watchlistAddress = copyaccount?.toString() || "0x92812499fF2c040f93121Aab684680a6e603C4A7";  // example watchlist address

  //  console.log({topUp: topUp})

    let address = subaccountAddress;
 //   let count = actionsCount;
 //   count =
 //   (await getCurrentMaxActionsCount({
 //     accountAddress: account!,
 //     subaccountAddress: address,
  //    chainId,
    //  signer,
 //   })) 

  
    try {
      // Displaying notification state change
    //  setNotificationState("activating");
    const stopLossUSD = calculateStopLossInUSD();
   // console.log("stopLossUSD",stopLossUSD);
    const takeprofitUSD = calculateTakeProfitInUSD();
    const numericStopLossUSD = parseFloat(stopLossUSD)*1000000;
    const numerictakeprofitUSD = parseFloat(takeprofitUSD)*1000000;
    const numericmaxMargin = parseFloat(maxMargin)*1000000;
    const numericleverage = (leverage)*10;
    const numericMargin = parseFloat(Margin)*1000000;
    const numericalskipLowerLev = parseFloat(skipLowerLev)*10;
    const numericalskipLowerColl = parseFloat(skipLowerColl)*10;
    //console.log('Maxmargin',maxMargin)
    //console.log('takeprofitUSD',takeprofitUSD)
    //console.log('stopLossUSD',stopLossUSD)


    const tx = await addUserOrUpdate(
      chainId,
      signer,
      "0x7F49B24E3fB86eb644ED51CA86E4442303a55B75",
      account,
      isCopyaccountActive ?? false,
  //    count,
      watchlistAddress,
      subscriptionEndDate,
      numericleverage,
      numericMargin,
      numericmaxMargin,
      selectedPairs,
      numericStopLossUSD,
      numerictakeprofitUSD,
      isUserActive,
      isWatchlistActiveMonitor,
      reverseCopy,
      collateralInc,
      numericalskipLowerLev,
      numericalskipLowerColl, 
      leverageCopy,
      copyAllTrades,
      setPendingTxns,
      {
        topUp: topUp,
        maxAutoTopUpAmount,
        wntForAutoTopUps,
        maxAllowedActions,
      }
    );

  
      // Assuming setActiveTx and setNotificationState are functions to handle transaction state and UI updates
      setActiveTx(tx.hash);
      setNotificationState("activated");
    } catch (error) {
      console.error("Failed to update or add user:", error);
     // setNotificationState("updateFailed");
      throw error;
    }
  }, [
    account,
    signer,
    chainId,
    setPendingTxns,
    setNotificationState,
    setActiveTx,    
    subaccountAddress,
    actionsCount,
    setNotificationState,
    isCopyaccountActive,
    setPendingTxns,
    topUp,
    maxAutoTopUpAmount,
    wntForAutoTopUps,
    maxAllowedActions,
    leverage,
    maxMargin,
    Margin,
    setActiveTx,
    selectedPairs,
    stopLossValue,
    takeProfitValue,
    leverageCopy,
    skipLowerColl,
    skipLowerLev,
    copyAllTrades
  ]);


  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: getContract(chainId, "SyntheticsRouter"),
    tokenAddresses: [wrappedToken.address,"0xaf88d065e77c8cC2239327C5EDb3A432268e5831"],
    skip: !isVisible,
  });

  

 // console.log('tokensAllowanceData',tokensAllowanceData)

 // const needPayTokenApproval = true;
  
  const needPayTokenApproval = useMemo(
    () =>
      tokensAllowanceData && baseFeePerAction !== undefined
        ? getNeedTokenApprove(tokensAllowanceData, wrappedToken.address, baseFeePerAction)
        : false,
    [baseFeePerAction, tokensAllowanceData, wrappedToken.address]
  );

  const needPayTokenApproval2 = useMemo(
    
    () =>
    
      tokensAllowanceData && Margin !== undefined && !isNaN(parseFloat(Margin))
        ? getNeedTokenApprove2(
            tokensAllowanceData,
            "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
            BigInt(parseFloat(Margin) * 1000000)
          )
        : false,
    [Margin, tokensAllowanceData]
  );

 // console.log('needPayTokenApproval2',needPayTokenApproval2) 
 // console.log('Margin',Margin)
 

   // console.log('BigInt(parseFloat(Margin) * 1000000)',BigInt(parseFloat(Margin) * 1000000))


  const { text: buttonText, disabled } = useMemo(
    () =>
      getButtonState({
        mainAccEthBalance: mainAccNativeTokenBalance,
        subaccountAddress,
        topUp,
        maxAutoTopUpAmount,
        wntForAutoTopUps,
        maxAllowedActions,
        withdrawalLoading,
        formState,
        notificationState,

        needPayTokenApproval,
        needPayTokenApproval2,
        isTxPending,

        isCopyaccountActive,
        accountUpdateLoading: isCopyaccountUpdating,

        nativeTokenSymbol: nativeToken.symbol,
        wrappedTokenSymbol: wrappedToken.symbol,
    //    insufficientFunds: isInsufficientFunds
      }),
    [
      mainAccNativeTokenBalance,
      subaccountAddress,
      topUp,
      maxAutoTopUpAmount,
      wntForAutoTopUps,
      formState,
      maxAllowedActions,
      withdrawalLoading,
      notificationState,
      needPayTokenApproval,
      needPayTokenApproval2,
      isTxPending,
      isCopyaccountActive,
      isCopyaccountUpdating,
      nativeToken.symbol,
      wrappedToken.symbol,
    ]
  );

  const gasPrice = useGasPrice(chainId);

  const subaccount = useCopyaccount(null, 1);
 // console.log("subaccount withdrawal",subaccount)

  let isInsufficientFunds = false;

  const defaultExecutionFee = useCopyaccountDefaultExecutionFee();
  const required =  defaultExecutionFee;
 
 // console.log("required insufficient",required)
 
  if(subAccNativeTokenBalance){
 
   if(required > BigInt(subAccNativeTokenBalance))
   {
     isInsufficientFunds = true
   }
 
 }

  const handleWithdrawClick = useCallback(async () => {
    if (!subaccount) throw new Error("privateKey is not defined");
    if (!account) throw new Error("account is not defined");
    if (!signer) throw new Error("signer is not defined");
    if (subAccNativeTokenBalance === undefined) throw new Error("subEthBalance is not defined");
    if (gasPrice === undefined) throw new Error("gasPrice is not defined");

    setWithdrawalLoading(true);

    try {
      helperToast.success(
        <StatusNotification title={t`Withdrawing from Copyaccount`}>
          {t`Withdrawing ${formatTokenAmount(BigInt(subAccNativeTokenBalance), nativeToken.decimals, nativeToken.symbol, {
            displayDecimals: 4,
          })} to Main Account`}
        </StatusNotification>
      );

    //  await withdrawFromCopyaccount({
    //    mainAccountAddress: account,
   //     subaccount,
  //    });
//console.log("Withdraw activated")
       await withdrawFromCopyaccount(
        chainId,
        signer,
        setPendingTxns
      );
      helperToast.success(
        <StatusNotification title={t`Withdrawing from Copyaccount`}>
          {t`Withdrawn ${formatTokenAmount(BigInt(subAccNativeTokenBalance), nativeToken.decimals, nativeToken.symbol, {
            displayDecimals: 4,
          })} to Main Account`}
        </StatusNotification>
      );
    } finally {
      setWithdrawalLoading(false);
    }
  }, [account, gasPrice, signer, subAccNativeTokenBalance, subaccount, nativeToken]);

  useEffect(() => {
    setNotificationState("none");
  }, [chainId, setNotificationState]);

  let tokenApproval: ReactNode = null;

 /* if (needPayTokenApproval && account) {
    tokenApproval = (
      <div className="CopyaccountModal-approve-token-btn">
        <ApproveTokenButton
          spenderAddress={getContract(chainId, "SyntheticsRouter")}
          tokenAddress={wrappedToken.address}
          tokenSymbol={wrappedToken.symbol}
        />
      </div>
    );
  }
*/

  if (needPayTokenApproval2 && account && isCopyaccountActive) {
    tokenApproval = (
      <div className="CopyaccountModal-approve-token-btn">
        <ApproveTokenButton
          spenderAddress={getContract(chainId, "SyntheticsRouter")}
          tokenAddress="0xaf88d065e77c8cC2239327C5EDb3A432268e5831"
          tokenSymbol={"USDC"}
        />
      </div>
    );
  }

  const handleCopyClick = useCallback(() => {
    if (!copyaccount) return;

    copyToClipboard(copyaccount);
    helperToast.success(t`Address copied to your clipboard`);
  }, [copyToClipboard, copyaccount]);

  const subAccNativeTokenBalanceFormatted = useMemo(
    () =>
      formatTokenAmount(BigInt(subAccNativeTokenBalance), nativeToken.decimals, nativeToken.symbol, {
        displayDecimals: 4,
      }),
    [nativeToken.decimals, nativeToken.symbol, subAccNativeTokenBalance]
  );

  const focusTopUpInput = useCallback(() => {
    topUpInputRef.current?.focus();
  }, []);

  const focusMaxAllowedActionsInput = useCallback(() => {
    maxAllowedActionsInputRef.current?.focus();
  }, []);

  const focusConvertInput = useCallback(() => {
    convertInputRef.current?.focus();
  }, []);

  const focusConvertLeverage = useCallback(() => {
    convertLeverageRef.current?.focus();
  }, []);

  const ToggleSwitch = ({ checked, onChange }) => (
    <div className={`toggle-switch ${checked ? 'active' : ''}`} onClick={() => onChange(!checked)}>
      <div className={`toggle-switch-knob ${checked ? 'active' : ''}`} />
    </div>
  );

  const withdrawalButton = (
    <button
      disabled={disabled || !subaccount}
      onClick={handleWithdrawClick}
      className="CopyaccountModal-mini-button w-full flex-1"
    >
      {withdrawalLoading ? <Trans>Withdrawing...</Trans> : <Trans>Withdraw</Trans>}
    </button>
  );

  return (
    <div className="CopyaccountModal-content">
      {
        <CopyaccountStatus
          hasBorder={Boolean(subaccountAddress)}
          onTopUpClick={focusTopUpInput}
          onMaxAllowedActionsClick={focusMaxAllowedActionsInput}
          onConvertClick={focusConvertInput}
        />
      }

{isInsufficientFunds && (
  <div className="mb-10">
    <p className="text-xl">
      The amount left in the copy-account is not enough to cover network gas costs.
    </p>
  </div>
)}


      {subaccountAddress && (
        <>
          <div className="CopyaccountModal-subaccount">
            <div className="CopyaccountModal-subaccount-details">
              
              <span className="CopyaccountModal-subaccount-label">
                <Trans>Address to Copy:</Trans>
              </span>
              <span>{shortenAddressOrEns(copyaccount ?? "", 13)}</span>
            </div>
            <div className="relative">
              <ButtonIcon onClick={handleCopyClick} icon={copyIcon} title="Copy" />
              <ExternalLink href={subaccountExplorerUrl}>
                <ButtonIcon icon={externalLinkIcon} title="Open in Explorer" />
              </ExternalLink>
            </div>
          </div>
          <div className="CopyaccountModal-buttons gap-4">
            {isInsufficientFunds ? (
              <TooltipWithPortal
                className="block flex-1"
                handleClassName="block w-full !no-underline"
                position="top"
                handle={withdrawalButton}
                content={<Trans>The amount left in the copy-account is not enough to cover network gas costs.</Trans>}
              />
            ) : (
              withdrawalButton
            )}
            <button
              disabled={disabled}
              onClick={handleDeactivateClick}
              className="CopyaccountModal-mini-button warning flex-1"
            >
              {notificationState === "deactivating" ? <Trans>Deactivating...</Trans> : <Trans>Deactivate</Trans>}
            </button>
          </div>
        </>
      )}

      <div className="CopyaccountModal-stats">
        <div className="CopyaccountModal-section">
          {subaccountAddress ? (
            <StatsTooltipRow
              label={t`Copy Account Balance`}
              showColon={false}
              value={
                isCopyaccountActive ? (
                  <TooltipWithPortal
                    handle={subAccNativeTokenBalanceFormatted}
                    renderContent={renderCopyaccountBalanceTooltipContent}
                    position="top-end"
                  />
                ) : (
                  subAccNativeTokenBalanceFormatted
                )
              }
              showDollar={false}
            />
          ) : (
            <div className="CopyaccountModal-section" />
          )}
          <StatsTooltipRow
            label={t`Main Account Balance`}
            showColon={false}
            value={
              <TooltipWithPortal
         /*    
                handle={formatTokenAmount(mainAccWrappedTokenBalance, wrappedToken.decimals, wrappedToken.symbol, {
                  displayDecimals: 4,
                })}

         */ 
                handle={formatTokenAmount(mainAccNativeTokenBalance, nativeToken.decimals, nativeToken.symbol, {
                  displayDecimals: 4,
                })}
                renderContent={renderMainAccountBalanceTooltipContent}
                position="top-end"
              />
            }
            showDollar={false}
          />
        </div>
        <div className="CopyaccountModal-section">

        <InputRow
    ref={convertMarginRef}
    value={Margin}
    setValue={setMargin} // Directly passing setMaxMargin to handle state updates
    label={t`Order Collateral`}
    symbol={"USDC"} // Assuming you want to show a currency symbol
    placeholder="0"
    inputTooltip={null} // Tooltip can be dynamic based on maxMargin value or any other logic
    description={t`This is the amount of collateral used for each trade when the address you copy opens a position.`}
/>

<InputRow
            ref={topUpInputRef}
            value={topUpString}
            setValue={setTopUpString}
            label={isCopyaccountActive ? t`Top-up for Gas Fee` : t`Initial top-up for Gas Fee`}
            symbol={nativeToken.symbol}
            placeholder="0.0000"
            inputTooltip={
              (topUp !== null &&
                topUp > 0 &&
                nativeTokenData &&
                formatUsd(convertToUsd(topUp, nativeToken.decimals, nativeTokenData.prices?.minPrice))) ||
              null
            }
            description={
              t`This amount of ETH will be transferred from your Main Account to your Copy Trading Balance to cover transaction fees, including order execution and gas fees. You can always withdraw this balance at any time. After order execution, any excess fees will be automatically refunded to your main wallet. Current Max cost per order:` +
              (nativeTokenData
                ? ` ${formatUsd(convertToUsd(baseFeePerAction, nativeToken.decimals, nativeTokenData.prices?.minPrice))}`
                : "")
            }
          />



     <div>
      <h4>Select Trading Pairs</h4>
      <TradingPairsInput selectedPairs={selectedPairs} onChange={handlePairsChange} />
      {/* Other UI elements */}

     </div>
 
     <div>
      <LeverageSlider
        initialValue={leverage}
        min={0.1}
        max={100}
        onChange={handleLeverageChange}
      />
      {/* other components */}
    </div>

    <div className="CopyaccountModal-section2">
  <div className="CopyaccountModal-input-wrapper2">
    <div className="CopyaccountModal-label-toggle-group2">
      <label className="CopyaccountModal-subaccount-label">Leverage Copy</label>
      <ToggleSwitch
        checked={leverageCopy}
        onChange={handleLeverageCopyChange}
      />
    </div>
    <p className="CopyaccountModal-subaccount-details">
      Keep Leverage as Trader.
    </p>
  </div>
</div>

    <div className="CopyaccountModal-section">
  <div className="CopyaccountModal-input-wrapper2">
    <div className="CopyaccountModal-label-toggle-group2">
      <label className="CopyaccountModal-subaccount-label">Reverse Copy</label>
      <ToggleSwitch
        checked={reverseCopy}
        onChange={handleReverseCopyChange}
      />
    </div>
    <p className="CopyaccountModal-subaccount-details">
      Example: Trader opened LONG, you will open SHORT.
    </p>
  </div>
</div>

    <div className="modal-stoploss-section">
  <div className="stoploss-wrapper">
    <label className="stoploss-label">Stop Loss (Recommended)</label>
    <div className="input-group">
      <input
        type="text"
        className="stoploss-input"
        value={stopLossValue}
        onChange={handleStopLossValueChange}
        placeholder="0"
      />
      <select
        className="stoploss-select"
        value={stopLossType}
        onChange={handleStopLossTypeChange}
      >
        <option value="USD">USD</option>
      </select>
    </div>
    <p className="stoploss-description">
      When the position's loss exceeds {calculateStopLossInUSD()} USD, the Stop Loss will be triggered. Your initial position size will be Margin * Leverage.
    </p>
  </div>
</div>

  
  

<div className="modal-stoploss-section">
  <div className="stoploss-wrapper">
    <label className="stoploss-label">Take Profit</label>
    <div className="input-group">
      <input
        type="text"
        className="stoploss-input"
        value={takeProfitValue}
        onChange={handleTakeProfitChange}
        placeholder="0"
      />
      <select
        className="stoploss-select"
        value={stopLossType}
        onChange={handleTakeProfitTypeChange}
      >
        <option value="USD">USD</option>
      </select>
    </div>
    <p className="stoploss-description">
    When the position's loss exceeds {calculateTakeProfitInUSD()} USD, the Stop Loss will be triggered.
    </p>
  </div>
</div>








            <div>
            <button
                className="toggle-button"
                onClick={() => setIsAdvancedSettingsVisible(!isAdvancedSettingsVisible)}
            >




        Advanced Settings
            </button>
            {isAdvancedSettingsVisible && (
                <>
                            <InputRow
                            ref={convertMaxMarginRef}
                             value={maxMargin}
                             setValue={setMaxMargin} // Directly passing setMaxMargin to handle state updates
                             label={t`Max Collateral Per Position`}
                             symbol={"USDC"} // Assuming you want to show a currency symbol
                             placeholder="0"
                             inputTooltip={null} // Tooltip can be dynamic based on maxMargin value or any other logic
                            description={t`When the trader increases their position, your margin will follow, up to the maximum of this amount.`}
                           />
                    <InputRow
                        ref={convertskipLowerLevRef}
                        value={skipLowerLev}
                        setValue={setskipLowerLev}
                        label={t`Skip Lower Leverage Position`}
                        symbol={""}
                        placeholder="0"
                        description={t`You will only copy positions that are opened with leverage equal to or higher than the selected leverage.`}
                    />
                    <InputRow
                        ref={convertskipLowerCollRef}
                        value={skipLowerColl}
                        setValue={setskipLowerColl}
                        label={t`Skip Lower Collateral Position`}
                        symbol={""}
                        placeholder="0"
                        description={t`You will not copy positions opened with collateral lower than the selected leverage amount.`}
                    />


<div className="CopyaccountModal-section">
  <div className="CopyaccountModal-input-wrapper2">
    <div className="CopyaccountModal-label-toggle-group2">
      <label className="CopyaccountModal-subaccount-label">Copy market Orders Only</label>
      <ToggleSwitch
        checked={copyAllTrades}
        onChange={handlecopyAllTradesChange}
      />
    </div>
    <p className="CopyaccountModal-subaccount-details">
      By Default all orders will be copied i.e Market and Limit (If enabled only market order will be copied).
    </p>
  </div>
</div>


{/*

                              <InputRow
            value={maxAutoTopUpAmountString}
            setValue={setMaxAutoTopUpAmountString}
            label={t`Max auto top-up amount`}
            symbol={wrappedToken.symbol}
            placeholder="0.0000"
            inputTooltip={
              (maxAutoTopUpAmount !== null &&
                maxAutoTopUpAmount > 0 &&
                wrappedTokenData &&
                formatUsd(convertToUsd(maxAutoTopUpAmount, nativeToken.decimals, wrappedTokenData.prices?.minPrice))) ||
              null
            }
            description={t`This is the maximum top-up amount that will be sent from your Main account to your Copyaccount after each transaction. The actual amount sent will depend on the final transaction fee.`}
          />  
             
                    <InputRow
            ref={convertInputRef}
            value={wntForAutoTopUpsString}
            setValue={setWntForAutoTopUpsString}
            label={t`Сonvert ${nativeToken.symbol} to ${wrappedToken.symbol}`}
            symbol={nativeToken.symbol}
            placeholder="0.0000"
            inputTooltip={
              (wntForAutoTopUps !== null &&
                wntForAutoTopUps > 0 &&
                nativeTokenData &&
                formatUsd(convertToUsd(wntForAutoTopUps, nativeToken.decimals, nativeTokenData.prices?.minPrice))) ||
              null
            }
            description={t`Convert this amount of ${nativeToken.symbol} to ${wrappedToken.symbol} in your Main Account to allow for auto top-ups, as only ${wrappedToken.symbol} can be automatically transferred to your Copyaccount. The ${wrappedToken.symbol} balance of your main account is shown above.`}
          />



           <InputRow
            ref={maxAllowedActionsInputRef}
            value={maxAllowedActionsString}
            setValue={setMaxAllowedActionsString}
            label={t`Max allowed actions`}
            symbol="Actions"
            placeholder="0"
            description={
              <div>
                <Trans>
                  For additional safety, subaccounts are only allowed to perform a specified number actions before
                  re-authorization from your main account is required. It takes total 3 actions to open a trade and set stop-loss and take-profit with it.
                </Trans>
              </div>
            }
          />

*/ }



                </>
            )}
        </div>


        </div>
        {tokenApproval}
        <Button onClick={handleButtonClick} disabled={disabled} variant="primary-action" className="w-full">
          {buttonText}
        </Button>
      


        <div className="mt-10">
      <div>
      <h1 className="CopyaccountModal-subaccount-label"></h1>
      <h1>Your Watchlist Addresses</h1>

    </div>
    </div>


<ul>
  {watchlistAddresses
    ?.filter((item) => item.isActiveMonitor)
    .map((item, index) => (
      <li key={item.addr}>
        <span>{item.addr}</span>
        <button
          onClick={() => handleRemoveWatchlistAddress(item.addr)}
          disabled={isRemoving}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded ml-2 disabled:opacity-50"
        >
          Remove
        </button>
      </li>
    ))}
</ul>

    </div>




    </div>

  );
});

const ButtonIcon = memo(({ icon, title, onClick }: { icon: string; title: string; onClick?: () => void }) => {
  return (
    <span title={title} className="CopyaccountModal-button-icon" onClick={onClick}>
      <img src={icon} alt={title} />
    </span>
  );
});

interface InputRowProps {
  value: string;
  setValue: (value: string) => void;
  label: string;
  symbol?: string;
  description: ReactNode;
  placeholder: string;
  negativeSign?: boolean;
  inputTooltip?: ReactNode;
}

const InputRowBase = forwardRef<HTMLInputElement, InputRowProps>(
  (
    {
      value,
      setValue,
      label,
      symbol = "",
      description,
      placeholder,
      negativeSign = false,
      inputTooltip,
    }: InputRowProps,
    ref
  ) => {
    const renderTooltipContent = useCallback(() => description, [description]);

    return (
      <div>
        <div className="CopyaccountModal-input-row flex text-gray-300">
          <div className="CopyaccountModal-input-row-label">
            <TooltipWithPortal position="top-start" handle={label} renderContent={renderTooltipContent} />
          </div>
          <Input
            ref={ref}
            negativeSign={negativeSign}
            placeholder={placeholder}
            tooltip={inputTooltip}
            value={value}
            setValue={setValue}
            symbol={symbol}
          />
        </div>
      </div>







    );
  }
);

const InputRow = memo(InputRowBase);

interface InputProp {
  value: string;
  setValue: (value: string) => void;
  symbol: string;
  placeholder: string;
  negativeSign: boolean;
  tooltip?: ReactNode;
}

const InputBase = forwardRef<HTMLInputElement, InputProp>(
  ({ value, setValue, symbol, placeholder, negativeSign, tooltip }, ref) => {
    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      },
      [setValue]
    );
    const id = useMemo(() => `input-${Math.random()}`, []);

    return (
      <div className="CopyaccountModal-input-wrapper">
        <div className={cx("CopyaccountModal-input")}>
          {negativeSign && <span className="CopyaccountModal-negative-sign">-</span>}
          <input ref={ref} placeholder={placeholder} onChange={onChange} id={id} value={value} />
          <label htmlFor={id}>
            <span>{symbol}</span>
          </label>
        </div>
        {tooltip && (
          <div className={cx("CopyaccountModal-field-info", "Tooltip-popup", "z-index-1001", "top-end")}>{tooltip}</div>
        )}
      </div>
    );
  }
);

const Input = memo(InputBase);
