import { Trans } from "@lingui/macro";
import { AlertInfo } from "components/AlertInfo/AlertInfo";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { getNativeToken, getWrappedToken } from "config/tokens";
import {
  useIsCopyaccountActive,
  useMainAccountInsufficientFunds,
  useCopyaccountActionCounts,
  useCopyaccountAddress,
  useCopyaccountInsufficientFunds,
} from "context/CopyaccountContext/CopyaccountContext";
import { SUBACCOUNT_DOCS_URL } from "domain/synthetics/copyaccount/constants";
import { useChainId } from "lib/chains";
import { ReactNode, memo } from "react";
import "./CopyaccountStatus.scss";
import useWallet from "lib/wallets/useWallet";

function CopyaccountStatusImpl({
  hasBorder,
  onTopUpClick,
  onConvertClick,
  onMaxAllowedActionsClick,
}: {

  hasBorder: boolean;
  onTopUpClick: () => void;
  onConvertClick: () => void;
  onMaxAllowedActionsClick: () => void;
}) {
  const isCopyaccountActive = useIsCopyaccountActive();
  const { remaining: remainingActionsCount } = useCopyaccountActionCounts();
 // const { signer, account } = useWallet();

  const shouldShowAllowedActionsError = isCopyaccountActive && remainingActionsCount == 0n;
 // const subAccountInsufficientFunds = useCopyaccountInsufficientFunds(account, null);
 // const subAccountInsufficientFunds = false;

 // console.log("subAccountInsufficientFunds",subAccountInsufficientFunds)
 // console.log("isCopyaccountActive",isCopyaccountActive)
 // const mainACcountInsufficientFunds = useMainAccountInsufficientFunds(null);
  const subaccountAddress = useCopyaccountAddress();
  const { chainId } = useChainId();
  //const shouldShowCopyaccountInsufficientFundsError = isCopyaccountActive && subAccountInsufficientFunds;
  const shouldShowCopyaccountInsufficientFundsError = false;

  //const shouldShowMainAccountInsufficientFundsError = isCopyaccountActive && mainACcountInsufficientFunds;
  const shouldShowMainAccountInsufficientFundsError = false;


  const content: ReactNode[] = [];

  if (
    shouldShowAllowedActionsError ||
    shouldShowCopyaccountInsufficientFundsError ||
    shouldShowMainAccountInsufficientFundsError
  ) {
    if (shouldShowAllowedActionsError) {
      content.push(
        <AlertInfo type="warning" key="1">
          <Trans>
            The maximum number of authorized Actions has been reached. Re-authorize a higher value using the "
            <span onClick={onMaxAllowedActionsClick} className="CopyaccountStatus-clickable-text">
              Max allowed actions
            </span>
            " field.
          </Trans>
        </AlertInfo>
      );
    }

    if (shouldShowCopyaccountInsufficientFundsError) {
      content.push(
        <AlertInfo type="warning" key="2">
          <Trans>
            There are insufficient funds in your Copy account for Copy Trading. Use the "
            <span onClick={onTopUpClick} className="CopyaccountStatus-clickable-text">
              Top-up
            </span>
            " field to increase the Copy account Balance.
          </Trans>
        </AlertInfo>
      );
    }

    if (shouldShowMainAccountInsufficientFundsError) {
      const wrappedToken = getWrappedToken(chainId);
      const nativeToken = getNativeToken(chainId);
      content.push(
        <AlertInfo type="warning" key="3">
          <Trans>
            Not enough {wrappedToken.symbol} on your Main Account. Use the "
            <span onClick={onConvertClick} className="CopyaccountStatus-clickable-text">
              Convert {nativeToken.symbol} to {wrappedToken.symbol}
            </span>
            " field to increase the Main Account {wrappedToken.symbol} balance.
          </Trans>
        </AlertInfo>
      );
    }
  } else if (!isCopyaccountActive) {
    return (
      <AlertInfo type="info" compact={!subaccountAddress}>
        <Trans>
          Activate and deposit ETH for gas to subaccount contract to start copy trading this address. All trades will open/close directly from/to your main account. Excess gas will be sent back to your main account with each trade automatically.
        </Trans>
      </AlertInfo>
    );
  } else {
    return null;
  }

  return <div className={`CopyaccountStatus ${hasBorder ? "CopyaccountStatus-border" : ""}`}>{content}</div>;
}

export const CopyaccountStatus = memo(CopyaccountStatusImpl) as typeof CopyaccountStatusImpl;
