import { Copyaccount } from "context/CopyaccountContext/CopyaccountContext";
import { bigMath } from "lib/bigmath";
import { ethers, Signer } from "ethers";
import { callContract } from "lib/contracts";
import { SUBACCOUNT_ORDER_ACTION } from "./constants";
import { getCopyaccountRouterContract, getCopyTradingContract } from "./getCopyaccountContract";
import { CopyaccountParams } from "./types";
import { BN_ZERO } from "lib/numbers";
import { useState, useEffect } from 'react';


export async function withdrawFromCopyaccount2({
  subaccount,
  mainAccountAddress,
}: {
  subaccount: Copyaccount;
  mainAccountAddress: string;
}) {
  if (!subaccount) throw new Error("No subaccount available.");

  const subaccountAddress = subaccount.address;
  const wallet = subaccount.signer;

  if (!wallet.provider) throw new Error("No provider available.");

  const [value, feeData] = await Promise.all([
    wallet.provider.getBalance(subaccountAddress),
    wallet.provider.getFeeData(),
  ]);
  const gasPrice = feeData.gasPrice ?? 0n;
  const gasLimit = 21000n;
  const approxAmountToSend = value - gasPrice * gasLimit;

  if (approxAmountToSend < 0) {
    throw new Error("Insufficient funds to cover gas cost.");
  }

  const estimatedGas = bigMath.mulDiv(
    (await wallet.estimateGas({
      to: mainAccountAddress,
      value,
    })) as bigint,
    100n,
    95n
  );

  const gasCost = estimatedGas * gasPrice;
  const amountToSend = value - gasCost;

  if (amountToSend < 0) {
    throw new Error("Insufficient funds to cover gas cost.");
  }

  const signedTransaction = await wallet.sendTransaction({
    to: mainAccountAddress,
    value: amountToSend,
    gasLimit: estimatedGas,
    gasPrice,
    nonce: await wallet.getNonce(),
  });

  return signedTransaction.wait();
}



export async function withdrawFromCopyaccount(
  chainId: number,
  signer: Signer,
  setPendingTxns: (txns: any[]) => void
) {
  const userManagementContract = getCopyTradingContract(chainId, signer);

  // Get the user's address
  const userAddress = await signer.getAddress();

  // Get the user's balance from the contract and convert it to bigint
  const userBalanceBigNumber = await userManagementContract.getUserETHBalanace(userAddress);
  //const userBalance: bigint = userBalanceBigNumber.toBigInt();

  // Check if the user has a balance to withdraw
  if (userBalanceBigNumber === 0n) {
    throw new Error("User has no balance to withdraw");
  }

  // Call the withdraw function with the user's balance as a BigNumber
  const params = [userBalanceBigNumber];

  return callContract(chainId, userManagementContract, "withdraw", params, {
    setPendingTxns,
    hideSentMsg: true,
    hideSuccessMsg: true,
  });
}

export async function removeWatchlistAddress(
  chainId,
  signer,
  addressToRemove,
  setPendingTxns
) {
  const userManagementContract = getCopyTradingContract(chainId, signer);

  // Assuming your contract has a public function to update the watchlist address
  // For example: function updateWatchlistAddress(address _watchlistAddress, bool _isActiveMonitor) public
  const params = [addressToRemove, false];

  return callContract(chainId, userManagementContract, "addOrUpdateWatchlistAddress", params, {
    setPendingTxns,
    hideSentMsg: true,
    hideSuccessMsg: true,
  });
}


export function useDirectBalanceFetch(chainId: number, signer: Signer | undefined, account: string) {
  const [balance, setBalance] = useState<bigint | null>(null);

  useEffect(() => {

   // console.log("chainId:", chainId);
   // console.log("account:", account);
   // console.log("signer:", signer);

    let isMounted = true;

    async function fetchBalance() {
      try {
        // Get the contract instance
        if (!chainId || !account || !signer) {
          setBalance(null);
          return;
        }
        const userManagementContract = getCopyTradingContract(chainId, signer);

        // Fetch the user's balance from the contract and convert to bigint
        const userBalanceBigNumber = await userManagementContract.getUserETHBalanace(account);
      //  console.log("userBalanceBigNumber",userBalanceBigNumber)
        const userBalance: bigint = userBalanceBigNumber.toBigInt();

        if (isMounted) {
          setBalance(userBalance);
        }
      } catch (error) {
        console.error('Error fetching user balance:', error);
        if (isMounted) {
          setBalance(null);
        }
      }
    }

    fetchBalance();

    // Cleanup function to prevent setting state on an unmounted component
    return () => {
      isMounted = false;
    };
  }, [chainId, account, signer]);

  return balance;
}


