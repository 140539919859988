import { Trans } from "@lingui/macro";
import DataStore from "abis/DataStore.json";
import {
  ARBITRUM,
  AVALANCHE,
  AVALANCHE_FUJI,
  NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR,
  getRpcUrl,
  getFallbackRpcUrl,
} from "config/chains";
import { getContract } from "config/contracts";
import {
  SUBACCOUNT_ORDER_ACTION,
  maxAllowedCopyaccountActionCountKey,
  subaccountActionCountKey,
  subaccountAutoTopUpAmountKey,
  subaccountListKey,
} from "config/dataStore1";
import { getCopyaccountConfigKey } from "config/localStorage";
import { getNativeToken, getWrappedToken } from "config/tokens";
import cryptoJs from "crypto-js";
import { useTransactionPending } from "domain/synthetics/common/useTransactionReceipt";
import {
  estimateExecuteIncreaseOrderGasLimit,
  getExecutionFee,
  useGasLimits,
  useGasPrice,
} from "domain/synthetics/fees";
import { STRING_FOR_SIGNING } from "domain/synthetics/subaccount/constants";
import { CopyaccountSerializedConfig } from "domain/synthetics/subaccount/types";
import { useTokenBalances, useTokensDataRequest, useDirectBalanceFetch } from "domain/synthetics/tokens";
import { ethers } from "ethers";
import { useChainId } from "lib/chains";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { useMulticall } from "lib/multicall";
import { applyFactor } from "lib/numbers";
import { getByKey } from "lib/objects";
import useWallet from "lib/wallets/useWallet";
import { Context, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { clientToSigner } from "lib/wallets/useEthersSigner";
import { estimateOrderOraclePriceCount } from "domain/synthetics/fees/utils/estimateOraclePriceCount";
import React, {  useContext } from 'react';
import { getCopyaccountRouterContract, getCopyTradingContract } from "domain/synthetics/subaccount/getCopyaccountContract";





export type Copyaccount = ReturnType<typeof useCopyaccount>;

export type CopyaccountNotificationState =
  | "generating"
  | "activating"
  | "activated"
  | "activationFailed"
  | "generationFailed"
  | "deactivating"
  | "deactivated"
  | "deactivationFailed"
  | "none";
  
   

export type CopyaccountContext = {
  activeTx: string | null;
  defaultExecutionFee: bigint | null;
  defaultNetworkFee: bigint | null;
  contractData: {
    isCopyaccountActive: boolean;
    maxAllowedActions: bigint;
    currentActionsCount: bigint;
    currentAutoTopUpAmount: bigint;
  } | null;
  subaccount: {
    address: string;
    privateKey: string;
  } | null;
  modalOpen: boolean;
  notificationState: CopyaccountNotificationState;
  selectedAccountAddress: string | null;
  setSelectedAccountAddress: (address: string | null) => void;
  
  clearCopyaccount: () => void;
  generateCopyaccount: () => Promise<string | null>;
  setActiveTx: (tx: string | null) => void;
  setModalOpen: (v: boolean) => void;
  setNotificationState: (state: CopyaccountNotificationState) => void;
  refetchContractData: () => void;
};

const context = createContext<CopyaccountContext | null>(null);
const routerAddress = "0x7F49B24E3fB86eb644ED51CA86E4442303a55B75"; // Replace with your deployed CopyTradeRouter address


// TODO gmxer: refactor this in chains.ts so that new networks are required
function getFactorByChainId(chainId: number) {
  switch (chainId) {
    case ARBITRUM:
    case AVALANCHE_FUJI:
    case AVALANCHE:
      return NETWORK_EXECUTION_TO_CREATE_FEE_FACTOR[chainId];

    default:
      throw new Error(`Unsupported chainId ${chainId}`);
  }
}



export function CopyaccountContextProvider({ children }: PropsWithChildren) {
//  console.log('Initializing CopyaccountContextProvider');

  const [modalOpen, setModalOpen] = useState(false);
 //console.log('Modal Open State:', modalOpen);

  const [notificationState, setNotificationState] = useState<CopyaccountNotificationState>("none");
//  console.log('Notification State:', notificationState);

  const [selectedAccountAddress, setSelectedAccountAddress] = useState<string | null>(null); // New state for selected account address


  const { signer, account } = useWallet();
  const { chainId } = useChainId();
 // console.log('Wallet details:', { signer, account, chainId });

  const [config, setConfig] = useLocalStorageSerializeKey<CopyaccountSerializedConfig>(
    getCopyaccountConfigKey(chainId, account),
    null
  );
 // console.log('Config from localStorage:', config);

  

  const gasPrice = useGasPrice(chainId);
 // console.log('Gas data:', { gasPrice });
  const gasLimits = useGasLimits(chainId);
  const { tokensData } = useTokensDataRequest(chainId);
  //console.log('Gas and token data:', { gasPrice, gasLimits, tokensData });

  const [defaultExecutionFee, defaultNetworkFee] = useMemo(() => {
    if (!gasLimits || !tokensData || gasPrice === undefined) {
     // console.log('Missing data for fee calculation:', { gasLimits, tokensData, gasPrice });
      return [null, null];
    }

    const gasLimit = estimateExecuteIncreaseOrderGasLimit(gasLimits, { swapsCount: 1 });
    const oraclePriceCount = estimateOrderOraclePriceCount(1);
    const executionFee = getExecutionFee(chainId, gasLimits, tokensData, gasLimit, gasPrice, oraclePriceCount)?.feeTokenAmount ?? 0n;

    const approxNetworkGasLimit = applyFactor(
      applyFactor(gasLimits.estimatedGasFeeBaseAmount, gasLimits.estimatedFeeMultiplierFactor),
      getFactorByChainId(chainId)
    ) + 800_000n; // L2 Gas for smaller operations like createOrder
    const networkFee = approxNetworkGasLimit * gasPrice;

   // console.log('Fees calculated:', { executionFee, networkFee });

    return [executionFee, networkFee];
  }, [chainId, gasLimits, gasPrice, tokensData]);

  const generateCopyaccount = useCallback(async () => {
    //console.log('Attempting to generate copy account');
    if (!account) throw new Error("Account is not set");

  //  const signature = await signer?.signMessage(STRING_FOR_SIGNING);
  //  if (!signature) return null;

 //   const pk = ethers.keccak256(signature);
  //  const subWallet = new ethers.Wallet(pk);

    const subWallet = routerAddress;   //new added
    //const encrypted = cryptoJs.AES.encrypt(pk, account);
 //   setConfig({
 //     privateKey: encrypted.toString(),
  //    address: subWallet.address,
  //  });

    setConfig({
      privateKey: subWallet,
      address: subWallet,
    });

    //console.log('Generated new copy account:', subWallet.address);
    return subWallet;
  }, [account, setConfig, signer]);

  const clearCopyaccount = useCallback(() => {
   // console.log('Clearing copy account');
    setConfig(null);
  }, [setConfig]);

  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [contractData, setContractData] = useState<CopyaccountContext["contractData"] | null>(null);
  const isTxPending = useTransactionPending(activeTx);

  const {
    data: fetchedContractData,
    isLoading,
    mutate: refetchContractData,
  } = useMulticall(chainId, "useSubaccountsFromContracts", {
    key: account && config?.address ? [account, config.address, activeTx, isTxPending ? "pending" : "not-pending"] : null,
    request: () => {
      return {
        dataStore: {
          contractAddress: getContract(chainId, "DataStore"),
          abi: DataStore.abi,
          calls: {
            isCopyaccountActive: { methodName: "containsAddress", params: [subaccountListKey(account!), config!.address] },
            maxAllowedActionsCount: { methodName: "getUint", params: [maxAllowedCopyaccountActionCountKey(account!, config!.address, SUBACCOUNT_ORDER_ACTION)] },
            currentActionsCount: { methodName: "getUint", params: [subaccountActionCountKey(account!, config!.address, SUBACCOUNT_ORDER_ACTION)] },
            currentAutoTopUpAmount: { methodName: "getUint", params: [subaccountAutoTopUpAmountKey(account!, config!.address)] },
          },
        },
      };
    },
    parseResponse: (res) => {
      const isCopyaccountActive = Boolean(res.data.dataStore.isCopyaccountActive.returnValues[0]);
      const maxAllowedActions = BigInt(res.data.dataStore.maxAllowedActionsCount.returnValues[0]);
      const currentActionsCount = BigInt(res.data.dataStore.currentActionsCount.returnValues[0]);
      const currentAutoTopUpAmount = BigInt(res.data.dataStore.currentAutoTopUpAmount.returnValues[0]);

     // console.log('Contract data fetched:', { isCopyaccountActive, maxAllowedActions, currentActionsCount, currentAutoTopUpAmount });

      return { isCopyaccountActive, maxAllowedActions, currentActionsCount, currentAutoTopUpAmount };
    },
  });

  useEffect(() => {
  //  console.log('Contract data loading status:', isLoading);
    if (!isLoading) {
  //    console.log('Updating contract data state:', fetchedContractData);
      setContractData(fetchedContractData ?? null);
    }
  }, [fetchedContractData, isLoading]);

  //console.log({selectedAccountAddress: selectedAccountAddress})

  const value: CopyaccountContext = useMemo(() => {

    return {
      modalOpen,
      setModalOpen,
      defaultExecutionFee,
      defaultNetworkFee,
      subaccount: config ? { address: config.address, privateKey: config.privateKey } : null,
      contractData: config && contractData ? contractData : null,
      refetchContractData,
      generateCopyaccount,
      clearCopyaccount,
      notificationState,
      selectedAccountAddress, // Add this line to expose the address state
      setSelectedAccountAddress,
      activeTx,
      setActiveTx,
      setNotificationState,
    };
  }, [
    activeTx,
    clearCopyaccount,
    config,
    contractData,
    refetchContractData,
    defaultExecutionFee,
    defaultNetworkFee,
    generateCopyaccount,
    modalOpen,
    notificationState,
    selectedAccountAddress,
  ]);

  return <context.Provider value={value}>{children}</context.Provider>;
}


export function useCopyaccountSelector<Selected>(selector: (s: CopyaccountContext) => Selected) {

  return useContextSelector(context as Context<CopyaccountContext>, selector) as Selected;
}

//export function useSelectedAccountSelector<Selected>(selector: (s: CopyaccountContext) => Selected) {

  //return useContextSelector(context as Context<CopyaccountContext>, selector) as Selected;
//}


export function useCopyaccountModalOpen() {
  return [useCopyaccountSelector((s) => s.modalOpen), useCopyaccountSelector((s) => s.setModalOpen)] as const;
}


export function useCopyaccountGenerateCopyaccount() {
  return useCopyaccountSelector((s) => s.generateCopyaccount);
}

export function useCopyaccountState() {
  return useCopyaccountSelector((s) => s);
}

export function useCopyaccountAddress() {

  return useCopyaccountSelector((s) => s.subaccount?.address ?? null);
}

//export function useSelectedAccountModalOpen() {
//  return useCopyaccountSelector((s) => s.setSelectedAccountAddress);
//}

//export function useSelectedAccountModalOpen() {
 // return [useSelectedAccountSelector((s) => s.setSelectedAccountAddress), useSelectedAccountSelector((s) => s.setSelectedAccountAddress)] as const;
//}


//export function useSelectedAccountAddress() {
//  return useSelectedAccountSelector((s) => s.setSelectedAccountAddress ?? null);
//}

function useCopyaccountPrivateKey() {
  const encryptedString = useCopyaccountSelector((s) => s.subaccount?.privateKey ?? null);
  const { account } = useWallet();
  return useMemo(() => {
    if (!account || !encryptedString) return null;

    // race condition when switching accounts:
    // account is already another address
    // while the encryptedString is still from the previous account
    try {
      return cryptoJs.AES.decrypt(encryptedString, account).toString(cryptoJs.enc.Utf8);
    } catch (e) {
      return null;
    }
  }, [account, encryptedString]);
}

export function useIsCopyaccountActive() {
 // const pkAvailable = useCopyaccountPrivateKey() !== null;
 // return useCopyaccountSelector((s) => s.contractData?.isCopyaccountActive ?? false) && pkAvailable;
  return useCopyaccountSelector((s) => s.contractData?.isCopyaccountActive ?? false);

}

export function useIsCopyaccountActive2() {
  const [isCopyaccountActive, setIsCopyaccountActive] = useState(false);
  const { signer, account } = useWallet();
  const { chainId } = useChainId();

  useEffect(() => {
    if (!account || !signer) return; // Guard clause if no account or library

    const routerContract = getCopyaccountRouterContract(chainId, signer);

    const checkUserActive = async () => {
      try {
        const isActive = await routerContract.isUserActive();
        setIsCopyaccountActive(isActive);
      } catch (error) {
        console.error('Failed to check if user is active:', error);
        setIsCopyaccountActive(false); // Reset state or handle error
      }
    };

    checkUserActive();
  }, [account, signer, chainId]); // Dependency array includes chainId if it affects contract instantiation

  return isCopyaccountActive;
}

export function useCopyaccountDefaultExecutionFee() {
  return useCopyaccountSelector((s) => s.defaultExecutionFee) ?? 0n;
}

export function useCopyaccountDefaultNetworkFee() {
  return useCopyaccountSelector((s) => s.defaultNetworkFee) ?? 0n;
}

function useCopyaccountCustomSigners() {
  const { chainId } = useChainId();
  const privateKey = useCopyaccountPrivateKey();

  return useMemo(() => {
    const publicRpc = getRpcUrl(chainId);
    const fallbackRpc = getFallbackRpcUrl(chainId);

    const rpcUrls: string[] = [];

    if (publicRpc) rpcUrls.push(publicRpc);
    if (fallbackRpc) rpcUrls.push(fallbackRpc);

    if (!rpcUrls.length || !privateKey) return undefined;

    return rpcUrls.map((rpcUrl) => {
      const provider = new ethers.JsonRpcProvider(rpcUrl, chainId, {
        staticNetwork: ethers.Network.from(chainId),
      });

      return new ethers.Wallet(privateKey, provider);
    });
  }, [chainId, privateKey]);
}

export function useCopyaccount(requiredBalance: bigint | null, requiredActions = 1) {
  const address = useCopyaccountAddress();
//  console.log("address",address)
  const active = useIsCopyaccountActive();  
 // console.log("active",active)
 // const privateKey = useCopyaccountPrivateKey();
 // const privateKey = "0xPrivatekey";

 // console.log("privateKey",privateKey)
  const defaultExecutionFee = useCopyaccountDefaultExecutionFee();
 // console.log("defaultExecutionFee",defaultExecutionFee)
 // const insufficientFunds = useCopyaccountInsufficientFunds(requiredBalance ?? defaultExecutionFee);
 // console.log("insufficientFunds",insufficientFunds)
  const subaccountCustomSigners = useCopyaccountCustomSigners();
  //const subaccountCustomSigners = 'SIGNER';

 // console.log("subaccountCustomSigners",subaccountCustomSigners)

  const { remaining } = useCopyaccountActionCounts();
 // console.log("remaining",remaining)
  const { walletClient } = useWallet();

  return useMemo(() => {
    if (
      !address ||
      !active ||
    //  !privateKey ||
      !walletClient ||
   //   insufficientFunds ||
      remaining === undefined ||
      remaining < Math.max(1, requiredActions)
    )
      return null;

    const signer = clientToSigner(walletClient);

   // const wallet = new ethers.Wallet(privateKey, signer.provider);
    return {
      address,
      active,
    //  signer: wallet,
    //  customSigners: subaccountCustomSigners,
    };
  }, [
    address,
    active,
  //  privateKey,
  //  insufficientFunds,
    walletClient,
    remaining,
    requiredActions,
  //  subaccountCustomSigners,
  ]);
}

export function useCopyaccountInsufficientFunds(requiredBalance?: bigint | null) {
  const { chainId } = useChainId();
  const subaccountAddress = useCopyaccountAddress();
 // const { signer, account } = useWallet();
  const subBalances = useTokenBalances(chainId, subaccountAddress ?? undefined);
 // const subBalances = useDirectBalanceFetch(chainId);
  const nativeToken = useMemo(() => getNativeToken(chainId), [chainId]);
//  if(!subBalances.balance){
 //   return;
 // }
 // const nativeTokenBalance = BigInt(subBalances.balance);
  const nativeTokenBalance = getByKey(subBalances.balancesData, nativeToken.address);
//console.log("subBalances insufficient",subBalances)
//console.log("account insufficient",account)
  const isCopyaccountActive = useIsCopyaccountActive();
  const defaultExecutionFee = useCopyaccountDefaultExecutionFee();
  const networkFee = useCopyaccountDefaultNetworkFee();
  const required = (requiredBalance ?? defaultExecutionFee ?? 0n) + networkFee;
//  console.log("required insufficient",required)

  if (!isCopyaccountActive) return false;
  if (nativeTokenBalance === undefined) return false;

  return required > nativeTokenBalance;
}

export function useMainAccountInsufficientFunds(requiredBalance: bigint | undefined | null) {
  const { chainId } = useChainId();
  const { account: address } = useWallet();
  const balances = useTokenBalances(chainId, address);
  const wrappedToken = useMemo(() => getWrappedToken(chainId), [chainId]);
  const wntBalance = getByKey(balances.balancesData, wrappedToken.address);
  const isCopyaccountActive = useIsCopyaccountActive();
  const networkFee = useCopyaccountDefaultNetworkFee();
  const defaultExecutionFee = useCopyaccountDefaultExecutionFee();
  const required = (requiredBalance ?? defaultExecutionFee) + networkFee;

  if (!isCopyaccountActive) return false;
  if (wntBalance === undefined) return false;

  return required > wntBalance;
}

export function useCopyaccountActionCounts() {
  const current = useCopyaccountSelector((s) => s.contractData?.currentActionsCount ?? null);
  const max = useCopyaccountSelector((s) => s.contractData?.maxAllowedActions ?? null);
  const remaining = max !== null ? max - (current ?? 0n) : 0n;

  return {
    current,
    max,
    remaining,
  };
}

export function useCopyaccountPendingTx() {
  return [useCopyaccountSelector((s) => s.activeTx), useCopyaccountSelector((s) => s.setActiveTx)] as const;
}

export function useIsLastCopyaccountAction(requiredActions = 1) {
  const { remaining } = useCopyaccountActionCounts();
  return remaining === BigInt(Math.max(requiredActions, 1));
}

export function useCopyaccountCancelOrdersDetailsMessage(
  overridedRequiredBalance: bigint | undefined,
  actionCount: number
) {
  const defaultRequiredBalance = useCopyaccountDefaultExecutionFee();
  const requiredBalance = overridedRequiredBalance ?? defaultRequiredBalance;
  const isLastAction = useIsLastCopyaccountAction(actionCount);
  const subaccountInsufficientFunds = useCopyaccountInsufficientFunds(requiredBalance);
  const [, setOpenCopyaccountModal] = useCopyaccountModalOpen();
  const refetchContractData = useCopyaccountRefetchContractData();
  const handleOpenCopyaccountModal = useCallback(() => {
    setOpenCopyaccountModal(true);
    refetchContractData();
  }, [setOpenCopyaccountModal, refetchContractData]);

  return useMemo(() => {
    if (isLastAction) {
      return (
        <Trans>
          Max Action Count Reached.{" "}
          <span onClick={handleOpenCopyaccountModal} className="link-underline">
            Click here
          </span>{" "}
          to update.
        </Trans>
      );
    } else if (subaccountInsufficientFunds) {
      return (
        <Trans>
          There are insufficient funds in your Copyaccount for One-Click Trading.{" "}
          <span onClick={handleOpenCopyaccountModal} className="link-underline">
            Click here
          </span>{" "}
          to top-up.
        </Trans>
      );
    }

    return null;
  }, [isLastAction, handleOpenCopyaccountModal, subaccountInsufficientFunds]);
}

export function useCopyaccountNotificationState() {
  return [
    useCopyaccountSelector((s) => s.notificationState),
    useCopyaccountSelector((s) => s.setNotificationState),
  ] as const;
}

export function useCopyaccountRefetchContractData() {
  return useCopyaccountSelector((s) => s.refetchContractData);
}
