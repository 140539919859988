import { ethers, Signer } from "ethers";
import { callContract } from "lib/contracts";
import { SUBACCOUNT_ORDER_ACTION } from "./constants";
import { getCopyaccountRouterContract, getCopyTradingContract } from "./getCopyaccountContract";
import { CopyaccountParams } from "./types";
import { BN_ZERO } from "lib/numbers";

export async function initCopyaccount(
  chainId: number,
  signer: Signer,
  subaccountAddress: string,
  mainAccountAddress: string,
  isAccountActive: boolean,
  currentActionsCount: bigint | null,
  setPendingTxns: (txns: any[]) => void,
  { topUp, maxAllowedActions, maxAutoTopUpAmount, wntForAutoTopUps }: CopyaccountParams
) {
  const subaccountRouter = getCopyaccountRouterContract(chainId, signer);

  const multicall = [
 //   wntForAutoTopUps !== undefined &&
 //     wntForAutoTopUps !== null &&
 //     wntForAutoTopUps > 0 && { method: "sendWnt", params: [mainAccountAddress, wntForAutoTopUps] },
 //   topUp !== null &&
  //    topUp !== undefined &&
  //    topUp > 0 && { method: "sendNativeToken", params: [subaccountAddress, topUp] },
    !isAccountActive && { method: "addSubaccount", params: [subaccountAddress] },
    maxAllowedActions !== undefined &&
      maxAllowedActions !== null &&
      maxAllowedActions >= 0 && {
        method: "setMaxAllowedSubaccountActionCount",
        params: [subaccountAddress, SUBACCOUNT_ORDER_ACTION, maxAllowedActions + (currentActionsCount ?? 0n)],
      },
  //  maxAutoTopUpAmount !== undefined &&
  //    maxAutoTopUpAmount !== null &&
  //    maxAutoTopUpAmount >= 0 && {
  //      method: "setSubaccountAutoTopUpAmount",
   //     params: [subaccountAddress, maxAutoTopUpAmount],
 //     },
  ].filter(Boolean) as { method: string; params: any[] }[];

  const encodedPayload = multicall.map((call) =>
    subaccountRouter.interface.encodeFunctionData(call!.method, call!.params)
  );

 // const value = (topUp ?? BN_ZERO) + (wntForAutoTopUps ?? BN_ZERO);
  const value =  BN_ZERO;


  return callContract(chainId, subaccountRouter, "multicall", [encodedPayload], {
    value,
    setPendingTxns,
    hideSentMsg: true,
    hideSuccessMsg: true,
  });
}



export async function addUserOrUpdate(
  chainId: number,
  signer: Signer,
  subaccountAddress: string,
  mainAccountAddress: string,
  isAccountActive: boolean,
 // currentActionsCount: bigint | null,
  watchlistAddress: string,
  subscriptionEndDate: number,
  leverage: number,
  Margin: number,
  maxMargin: number,
  allowedMarkets: string[],
  stopLoss: number,
  takeProfit: number,
  isUserActive: boolean,
  isWatchlistActiveMonitor: boolean,
  reverseCopy: boolean,
  collateralInc: number,
  skipLowerLev: number,
  skipLowerColl: number, 
  keepLeverageasTrader: boolean,
  executeAllOrders: boolean,
  setPendingTxns: (txns: any[]) => void,
  { topUp, maxAllowedActions, maxAutoTopUpAmount, wntForAutoTopUps }: CopyaccountParams
) {

  const subaccountRouter = getCopyaccountRouterContract(chainId, signer);
 // console.log('allowedMarkets',allowedMarkets)

  const multicall = [

  ].filter(Boolean) as { method: string; params: any[] }[];

  const encodedPayload = multicall.map((call) =>
    subaccountRouter.interface.encodeFunctionData(call!.method, call!.params)
  );


//console.log('topUp',topUp)
 // const value = (topUp ?? BN_ZERO) + (wntForAutoTopUps ?? BN_ZERO);
 const value = (topUp ?? BN_ZERO)

 const uintnew = 0;

// console.log('topUp',topUp)

  const userSettings2 = {
    subscriptionEndDate,
    Margin,
    leverage,
    maxMargin,
    stopLoss,
    takeProfit,
    isUserActive,
    reverseCopy,
    collateralInc,
    skipLowerLev,
    skipLowerColl, 
    keepLeverageasTrader,
    executeAllOrders
};

const userSettings = {
  subscriptionEndDate: subscriptionEndDate ?? Date.now() + 1000 * 60 * 60 * 24 * 1000,
  Margin: typeof Margin === 'number' ? Margin : 0,
  leverage: typeof leverage === 'number' ? leverage : 0,
  maxMargin: typeof maxMargin === 'number' ? maxMargin : 0,
  stopLoss: typeof stopLoss === 'number' ? stopLoss : 0,
  takeProfit: typeof takeProfit === 'number' ? takeProfit : 0,
  isUserActive: typeof isUserActive === 'boolean' ? isUserActive : true,
  reverseCopy: typeof reverseCopy === 'boolean' ? reverseCopy : false,
  uintnew,
 // collateralInc: typeof collateralInc === 'number' ? collateralInc : 0,
  skipLowerLev: typeof skipLowerLev === 'number' ? skipLowerLev : 0,
  skipLowerColl: typeof skipLowerColl === 'number' ? skipLowerColl : 0,
  keepLeverageasTrader: typeof keepLeverageasTrader === 'boolean' ? keepLeverageasTrader : true,
  executeAllOrders: typeof executeAllOrders === 'boolean' ? executeAllOrders : true,
};
  const userManagementContract = getCopyTradingContract(chainId, signer); // Assumes you have a function to get the contract
//  console.log({userManagementContract: userManagementContract})
// console.log({userSettings: userSettings})

  const params = [
    watchlistAddress,
    userSettings,
    allowedMarkets,
    isWatchlistActiveMonitor,
    value,
    encodedPayload
  ];

  // console.log({value: value})


  return callContract(chainId, userManagementContract, "depositAndUpdateUser", params, {
    value,
    setPendingTxns,
    hideSentMsg: true,
    hideSuccessMsg: true,
  });


}


