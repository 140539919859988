import { Signer } from "ethers";
import { callContract } from "lib/contracts";
import { getCopyaccountRouterContract } from "./getCopyaccountContract";

export async function removeCopyaccount(chainId: number, signer: Signer, subaccountAddress: string) {
  const subaccountRouter = getCopyaccountRouterContract(chainId, signer);

  return callContract(chainId, subaccountRouter, "removeSubaccount", [subaccountAddress], {
    value: 0n,
    hideSentMsg: true,
    hideSuccessMsg: true,
  });
}


