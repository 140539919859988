import SubaccountRouter from "abis/SubaccountRouter.json";
import CopyaccountRouter from "abis/CopyaccountRouter.json";
import { getContract } from "config/contracts";
import { Signer, ethers } from "ethers";

export function getCopyaccountRouterContract(chainId: number, signer: Signer) {
  return new ethers.Contract(getContract(chainId, "SubaccountRouter"), SubaccountRouter.abi, signer);
}



export function getCopyTradingContract(chainId: number, signer: Signer) {
 // console.log({CopyaccountRouter: CopyaccountRouter.abi})

  //console.log({CopyaccountRoutercontract: getContract(chainId, "CopyTradingRouter")})
  return new ethers.Contract(getContract(chainId, "CopyTradingRouter"), CopyaccountRouter.abi, signer);
}
